import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useTelegram } from "context/telegram.provider";
import { FeatureWelcomePath } from "../routes";
import DatePicker from "widgets/forms/datepicker";
import { useForm } from "context/form.context";
import { ScreenZodiacsPath } from "./screenZodiacs";
import { getZodiac, getZodiacByDate } from "app/types/zodiac";

export const ScreenBirthdayPath = "birthday";
const ScreenBirthday = () => {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const { formData, updateFormData } = useForm();

  const { setMainButtonVisible, setMainButtonProps } = useTelegram()

  const submit = () => {
    console.log("go to zodiacs");
    return navigate(FeatureWelcomePath(ScreenZodiacsPath));
  }

  useEffect(() => {
    setMainButtonVisible(true);
    setMainButtonProps(t("features.welcome.screens.screenBirthday.mainButton"), submit);
  }, [ setMainButtonProps ]);

  const handleBirthdayChange = (v: number) => {
    updateFormData({
      ...formData,
      birthday: v,
      zodiacs: [ getZodiacByDate(new Date(v)) ]
    });
  }

  const handleTimeEditableChange = (v: boolean) => {
    updateFormData({ ...formData, isEditTime: v })
  }

  return (
    <div className="flex flex-col min-h-screen px-5 constellation">

      <div className="mb-5 flex flex-col items-center justify-center mt-8">
        <img className="w-12 h-12 mx-auto"
          src={`/ico/constellation/Constellation=${getZodiac(getZodiacByDate(new Date(formData.birthday)))}, Color=white.svg`}
          alt=""/>
        <h1 className="text-3xl font-bold pt-4 text-center text-tg-theme-section-header capitalize">
          {/* {t("features.welcome.screens.screenBirthday.title")} */}
          {getZodiac(getZodiacByDate(new Date(formData.birthday)))}
        </h1>
        <div className="w-40 h-40 mx-auto mb-5">
          <img className="w-full h-full"
            src={`/img/constellation/${getZodiac(getZodiacByDate(new Date(formData.birthday)))}.svg`}
            alt=""/>
        </div>
      </div>

      <div className="flex-1">

        <div className="mb-5 text-center">
          {t("features.welcome.screens.screenBirthday.desc")}
        </div>

        <div className="mb-5">
          <DatePicker
            value={formData.birthday}
            onChange={handleBirthdayChange}
            label="Дата рождения"
            timeLabel="Знаешь во сколько родился?"
            alwaysOpen={true}
            isTimeEditable={formData.isEditTime}
            onTimeEditableChange={handleTimeEditableChange}
          />
        </div>

      </div>

    </div>
  )
}

export default ScreenBirthday
