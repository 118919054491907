export enum PaymentType {
  Subscription = "subscription",
  Compatibility = "compatibility",
  NatalChartLove = "natal_chart_love",
  NatalChartWork = "natal_chart_work"
}

export enum PaymentStatus {
  Paid = "paid",
  Failed = "failed",
  Canceled = "canceled",
}

export enum PaymentOptionType {
  AdditionSubscription = "profile_subscription",
  BasicSubscription = "basic_subscription",
  NatalChartLoveAnalysis = "natal_chart_love_analysis",
  NatalChartWorkAnalysis = "natal_chart_work_analysis",
  CompatibilityAnalysis = "compatibility_analysis",
}

export type PaymentOption = {
  id: string;
  provider: string;
  name: string;
  currency: string;
  cost: number;
}

export type Payment = {
  ok: boolean
  link?: string
}