import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Drawer, useDrawer } from "context/drawer.context";
import { useTelegram } from "context/telegram.provider";
import { formatDate, handleError } from "utils";
import AstroProfileForm, { AstroProfile } from "widgets/forms/astroprofile";
import { PaymentForm } from "widgets/forms/payment/payment.form";
import CompatibilityHistoryList from "./compatibilityHistoryList";
import { CompatibilityProfileCreateRequest, useCompatibilityProfileCreateMutation } from "app/services/compatibility";
import { useProfileListQuery } from "app/services/profile";
import { PaymentOption, PaymentStatus, PaymentType } from "app/types/payment";
import { usePaymentCreateMutation } from "app/services/payment";
import { useWebApp } from "app/telegram";
import { useAccount } from "hooks/useAccount";
import { useAccountFetchQuery } from "app/services/account";
import { CompatibilityProfile } from "app/types/compatibility";

const CompatibilityProfilePage = () => {

  const { account } = useAccount();
  const webApp = useWebApp()
  const navigate = useNavigate()
  const { isOpen, openDrawer, closeDrawer } = useDrawer();
  const { setBackButtonVisible, setMainButtonVisible, setBackButtonOnClick } = useTelegram();

  const [ selectedProfile, setSelectedProfile ] = useState<AstroProfile>();
  const [ selectFn, setSelectFn ] = useState<(profile: AstroProfile) => void>();
  const [ selectedFirstProfile, setSelectedFirstProfile ] = useState<AstroProfile | null>(null);
  const [ selectedSecondProfile, setSelectedSecondProfile ] = useState<AstroProfile | null>(null);
  const [ unpaidCompatibility, setUnpaidCompatibility ] = useState<CompatibilityProfile | null>(null);

  const {
    data: profiles,
    isLoading: isProfilesLoading,
  } = useProfileListQuery();
  const { refetch: refetchAccount } = useAccountFetchQuery();
  const [ createCompatibility ] = useCompatibilityProfileCreateMutation();
  const [ createPayment, { isError: isCreatePaymentError } ] = usePaymentCreateMutation();

  useEffect(() => {
    refetchAccount();
  }, []);

  // Configure navigation routing
  useEffect(() => {
    setMainButtonVisible(false);
    setBackButtonVisible(true);
    setBackButtonOnClick(() => navigate("/astro"));
  }, []);

  // Add cleanup on unmount
  useEffect(() => {
    return () => {
      if (isOpen) {
        closeDrawer();
      }
    };
  }, [ isOpen, closeDrawer ]);

  useEffect(() => {
    if (!!profiles?.length && !selectedFirstProfile) {
      const p = profiles.find(p => p.is_default)
      if (!p) return
      const data: AstroProfile = {
        id: p.id,
        name: `${p.first_name || ""} ${p.last_name || ""}`.trim(),
        birthday: p.birthday,
        country: p.country,
        city: p.city,
        street: p.street,
        latitude: p.latitude,
        longitude: p.longitude,
        zodiac: p.zodiac
      };
      setSelectedFirstProfile(data)
    }
  }, [ profiles ]);

  if (isProfilesLoading) return <div/>;

  const handleSelect = (section: "first" | "second", profile?: AstroProfile) => {
    setSelectedProfile(profile)
    const newSelectFn = (profile: AstroProfile) => {
      if (section === "first") {
        setSelectedFirstProfile(profile);
      } else {
        setSelectedSecondProfile(profile);
      }
      closeDrawer();
    };

    setSelectFn(() => newSelectFn);
    openDrawer();
  };

  const prepareCompatibilityData = (): CompatibilityProfileCreateRequest => {
    const data: CompatibilityProfileCreateRequest = {};

    // Prepare first profile data
    if (!!selectedFirstProfile?.id) {
      data.profile_id = selectedFirstProfile.id;
    } else {
      const parts = selectedFirstProfile?.name.split(" ") || [];
      data.profile = {
        first_name: parts[0] || "",
        last_name: parts.slice(1).join(" ") || "",
        birthday: selectedFirstProfile?.birthday! / 1000,
        country: selectedFirstProfile?.country,
        city: selectedFirstProfile?.city,
        latitude: selectedFirstProfile?.latitude,
        longitude: selectedFirstProfile?.longitude
      };
    }

    // Prepare second profile data
    if (!!selectedSecondProfile?.id) {
      data.partner_profile_id = selectedSecondProfile.id;
    } else {
      const parts = selectedSecondProfile?.name.split(" ") || [];
      data.partner_profile = {
        first_name: parts[0] || "",
        last_name: parts.slice(1).join(" ") || "",
        birthday: selectedSecondProfile?.birthday! / 1000,
        country: selectedSecondProfile?.country,
        city: selectedSecondProfile?.city,
        latitude: selectedSecondProfile?.latitude,
        longitude: selectedSecondProfile?.longitude
      };
    }

    return data;
  };

  const handlePayment = async (provider: string, paymentOptionId: string) => {
    try {
      let compatibilityResult;

      if (unpaidCompatibility) {
        compatibilityResult = { ...unpaidCompatibility };
      } else {
        const data = prepareCompatibilityData();
        compatibilityResult = await createCompatibility(data).unwrap();
        if (!compatibilityResult) {
          throw new Error("Failed to create compatibility");
        }
      }

      refetchAccount();

      const paymentData = await createPayment({
        option_id: paymentOptionId,
        entity_id: compatibilityResult.id,
      }).unwrap();

      if (!isCreatePaymentError) {
        if (provider === "telegram") {
          webApp.openInvoice(paymentData.link, (event: string) => {
            if (event === PaymentStatus.Paid) {
              setUnpaidCompatibility(null);
              closeDrawer();
              navigate(`/compatibility/${compatibilityResult.id}`);
            }
          });
        } else {
          paymentData.ok && navigate(`/compatibility/${compatibilityResult.id}`);
        }
      }
    } catch (err) {
      handleError(err, err instanceof Error ? err.message : "An unexpected error occurred");
    }
  };

  const handlePaymentSubmit = (option: PaymentOption) => {
    console.log("Payment option:", option);
    handlePayment(option?.provider, option.id)
  };

  const handleCompatibilityRequest = async () => {
    setUnpaidCompatibility(null);

    if ((account?.compatibility_requests_remaining ?? 0) > 0) {
      try {
        const data = prepareCompatibilityData();
        const compatibilityResult = await createCompatibility(data).unwrap();

        if (!compatibilityResult) {
          throw new Error("Failed to create compatibility");
        }

        refetchAccount();

        if (compatibilityResult.status === "unpaid") {
          openDrawer("pay");
        } else {
          closeDrawer()
          navigate(`/compatibility/${compatibilityResult.id}`);
        }
      } catch (err) {
        handleError(err, err instanceof Error ? err.message : "An unexpected error occurred");
      }
    } else {
      openDrawer("pay");
    }
  };

  const handleCompatibilitySelect = (compatibility: CompatibilityProfile) => {
    if (compatibility.status === "unpaid") {
      setUnpaidCompatibility(compatibility);
      openDrawer("pay");
    } else {
      navigate(`/compatibility/${compatibility.id}`);
    }
  }

  return (
    <div>
      <div className="w-full mb-10 constellation">

        <div className="mb-10">
          <div className="flex flex-col items-center justify-center mt-8">
            <img className="w-12 h-12 mx-auto" src="/ico/zodicus.svg" alt=""/>
          </div>
          <h1 className="text-3xl font-bold py-4 text-center text-gold-gradient capitalize">
            Matching
          </h1>
          <div className="text-center">
            <p className="text-tg-theme-subtitle">
              Check compatibility between zodiac signs and astro-profiles
            </p>
          </div>
        </div>

        <div className="px-5 my-10">
          <ProfileSection
            profile={selectedFirstProfile}
            section="first"
            onSelect={handleSelect}
          />
        </div>

        <div className="w-full flex justify-center">
          <hr className="w-1/3"/>
        </div>

        <div className="px-5 my-10">
          <ProfileSection
            profile={selectedSecondProfile}
            section="second"
            onSelect={handleSelect}
          />
        </div>

        {(!!selectedFirstProfile && !!selectedSecondProfile) && (
          <div className="px-5 my-10 flex justify-center items-center">
            <div
              className="flex justify-center items-center rounded-xl p-4 bg-card-gradient mb-5 cursor-pointer w-4/5"
              onClick={handleCompatibilityRequest}
            >
              <div className="mb-1 flex justify-center items-center">
                <img src="/ico/arrow-right.svg" alt=""/>
              </div>
              <div className="px-5 font-bold flex-col text-gold-gradient flex items-center justify-center">
                <div>Показать совместимость</div>
                <small className="text-[#AFAFDE] text-xs">
                  {account.compatibility_requests_remaining} free requests available
                </small>
              </div>
              <div className="mb-1 flex justify-center items-center">
                <img src="/ico/arrow-left.svg" alt=""/>
              </div>
            </div>
          </div>
        )}

      </div>

      <div className="mb-1 flex justify-center">
        <img src="/ico/double-arrow-down.svg" alt=""/>
      </div>

      <div className="mx-5 my-10">
        <div className="w-full text-center mb-5">
          <h2 className="text-[#AFAFDE]">История запросов персональных совместимостей</h2>
        </div>

        <CompatibilityHistoryList onSelect={handleCompatibilitySelect}/>
      </div>

      <Drawer name="pay" maxHeight="50vh">
        <PaymentForm
          type={PaymentType.Compatibility}
          onSubmit={handlePaymentSubmit}
          buttonText="Заказать совместимость"
          description="Заказижите расширенную проверку совместимости с расшифровкой которая будет всегда вам доступна"
        />
      </Drawer>

      <Drawer title="Выберите астро-профиль">
        <div className="px-5">
          <AstroProfileForm
            profiles={profiles || []}
            value={selectedProfile}
            onSelect={(p) => selectFn ? selectFn(p) : null}/>
        </div>
      </Drawer>
    </div>
  )
}

interface ProfileSectionProps {
  profile: AstroProfile | null;
  section: "first" | "second";
  onSelect: (section: "first" | "second", profile?: AstroProfile) => void;
}

const ProfileSection: React.FC<ProfileSectionProps> = ({ profile, section, onSelect }) => {
  if (!profile) {
    return (
      <div className="px-5 my-10">
        <div className="flex flex-col items-center justify-center border-2 border-dashed rounded-xl p-10">
          <div className="text-center mb-6">
            Выбрать существующий профиль или заполнить данные
          </div>
          <button
            className="px-6 py-2 rounded-xl bg-card-gradient text-tg-theme-section-header"
            onClick={() => onSelect(section)}
          >
            Изменить
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="px-5 my-10">
      <div className="mb-5 flex flex-col items-center justify-center mt-8">
        <img className="w-32 h-32 mx-auto" src="/img/constellation/gemini.svg" alt=""/>
        <h1 className="text-base py-2 text-center text-gold-gradient">
          {profile.name}
        </h1>
        <p className="text-xs text-gold-gradient">
          {[ formatDate(new Date(profile.birthday)), profile.city, profile.country ].filter(Boolean).join(", ") || ""}
        </p>
      </div>

      <div className="w-full flex justify-center">
        <button
          className="px-6 py-2 rounded-xl bg-card-gradient text-tg-theme-section-header"
          onClick={() => onSelect(section, profile)}
        >
          Изменить
        </button>
      </div>
    </div>
  );
};

export default CompatibilityProfilePage