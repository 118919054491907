import { api } from "./api"
import { EmptySuccessResponse } from "./empty"
import { Profile } from "app/types/profile";
import { Zodiac } from "../types/zodiac";

function getProfileFromResponse(src: ProfileResponse): Profile {
  return {
    ...src,
    birthday: src.birthday * 1000,
  }
}

export type ProfileListFilter = {
  natal_chart: boolean
}

export type ProfileResponse = {
  id: string;
  first_name: string;
  last_name: string;
  birthday: number;
  zodiac: Zodiac;
  country: string;
  city: string;
  street: string;
  status: string;
  latitude: number;
  longitude: number;
  extended: boolean;
  extended_expiration: number;
  is_default: boolean;
  created: number;
  updated: number;
}

export type ProfileListResponse = {
  items: ProfileResponse[]
}

export type ProfileCreateRequest = {
  first_name: string;
  last_name: string;
  birthday: number;
  country: string;
  city: string;
  street: string;
  latitude: number;
  longitude: number;
  natal_chart_requested?: boolean;
}

export type ProfileUpdateRequest = Omit<ProfileCreateRequest, "is_favorites">

export const profileApi = api.injectEndpoints({
  endpoints: (build) => ({

    profileList: build.query<Profile[], ProfileListFilter | void>({
      query: (filter?: ProfileListFilter) => ({
        url: "v1/profiles",
        method: "GET",
        params: filter,
      }),
      transformResponse: (response: ProfileListResponse): Profile[] => {
        return response.items.map(getProfileFromResponse);
      },
      providesTags: (result: Profile[] = [], error, filter) => {
        if (!!filter && filter.natal_chart) {
          return [];
        }
        return [
          ...result.map(({ id }) => ({ type: "Profiles", id }) as const),
          { type: "Profiles" as const, id: "LIST" },
        ];
      },
    }),

    profileCreate: build.mutation<Profile, ProfileCreateRequest>({
      query: (data: ProfileCreateRequest) => ({
        url: "v1/profile",
        method: "POST",
        body: data,
      }),
      transformResponse: (response: ProfileResponse): Profile => {
        return getProfileFromResponse(response);
      },
      invalidatesTags: [ { type: "Profiles", id: "LIST" } ],
    }),

    profileFetch: build.query<ProfileResponse, string>({
      query: (id) => ({
        url: `v1/profile/${id}`,
        method: "GET",
      }),
      transformResponse: (response: ProfileResponse): Profile => {
        return getProfileFromResponse(response);
      },
      providesTags: (result, error, id) => [ { type: "Profiles", id } ],
    }),

    profileUpdate: build.mutation<ProfileResponse, {id: string, data: ProfileUpdateRequest}>({
      query: ({ id, data }) => ({
        url: `v1/profile/${id}`,
        method: "PUT",
        body: data,
      }),
      transformResponse: (response: ProfileResponse): Profile => {
        return getProfileFromResponse(response);
      },
      invalidatesTags: [ { type: "Profiles", id: "LIST" } ],
    }),

    profileDelete: build.mutation<EmptySuccessResponse, string>({
      query: (id) => ({
        url: `v1/profile/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [ { type: "Profiles", id } ],
    }),

  }),
})

export const {
  useProfileListQuery,
  useProfileCreateMutation,
  useProfileFetchQuery,
  useLazyProfileFetchQuery,
  useLazyProfileListQuery,
  useProfileUpdateMutation,
  useProfileDeleteMutation,
} = profileApi

export const {
  endpoints: {
    profileList,
    profileCreate,
    profileFetch,
    profileUpdate,
    profileDelete,
  },
} = profileApi

