import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Main from "layouts/protected";
import Loader from "./layouts/preloader";
import { useThemeParams } from "app/telegram";

function App() {
  let [ ColorScheme ] = useThemeParams();

  return (
    <div
      data-testid="app"
      className={`h-screen ${ColorScheme === "dark" ? "dark" : "light"}`}
    >
      <ScrollToTop />
      <Routes>
        <Route path="/loading" element={<Loader/>}/>
        <Route path="*" element={<Main/>}/>
      </Routes>
    </div>
  );
}

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [ pathname ]);

  return null;
}

export default App;
