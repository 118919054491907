import { useEffect, useState } from "react";
import ProfileForm from "./profile.form";
import { useTelegram } from "context/telegram.provider";
import { Profile } from "app/types/profile";
import {
  ProfileCreateRequest,
  useProfileCreateMutation,
  useProfileListQuery,
  useProfileUpdateMutation
} from "app/services/profile";
import { handleError } from "utils";
import { useNavigate } from "react-router-dom";
import { PaymentOptionType, PaymentStatus } from "app/types/payment";
import { usePaymentCreateMutation } from "app/services/payment";
import { useWebApp } from "app/telegram";

const ProfileCreatePage = () => {

  const navigate = useNavigate();
  const webApp = useWebApp()

  const [ formProfileData, setFormProfileData ] = useState({
    birthday: Date.UTC(new Date().getFullYear() - 20, 0, 1)
  } as Profile);
  const { setBackButtonVisible, setBackButtonOnClick, setMainButtonVisible, setMainButtonProps } = useTelegram()

  const [ createProfile ] = useProfileCreateMutation();
  const [ updateProfile ] = useProfileUpdateMutation();

  const {
    data: profiles,
    isLoading: isProfilesLoading,
  } = useProfileListQuery();

  const [ createPaymentLink, {
    isError: isCreatePaymentError
  } ] = usePaymentCreateMutation();

  const [ invalidFields, setInvalidFields ] = useState<string[]>([]);

  const [ createdProfile, setCreatedProfile ] = useState<Profile | null>(null);

  const validateProfile = () => {
    const invalid = [];
    if (!formProfileData.first_name) invalid.push("first_name");
    if (!formProfileData.last_name) invalid.push("last_name");
    if (!formProfileData.birthday) invalid.push("birthday");
    return invalid;
  };

  useEffect(() => {
    setBackButtonVisible(true)
    setBackButtonOnClick(() => navigate("/"))
  }, []);

  useEffect(() => {
    setMainButtonVisible(true);

    const handleClick = () => submit();

    setMainButtonProps("Оформить подписку", handleClick);
  }, [ profiles, formProfileData ]);

  const handleChange = (data: Partial<Profile>) => {
    setInvalidFields([]);
    setFormProfileData({ ...formProfileData, ...data })
  }

  const submit = async () => {
    const invalid = validateProfile();
    if (invalid.length > 0) {
      setInvalidFields(invalid);
      return;
    }
    setInvalidFields([]);

    try {
      let profileResult: Profile;

      if (createdProfile) {
        if (createdProfile.first_name === formProfileData.first_name &&
          createdProfile.last_name === formProfileData.last_name &&
          createdProfile.birthday === formProfileData.birthday) {
          profileResult = createdProfile;
        } else {
          const updateData = {
            id: createdProfile.id,
            data: {
              ...createdProfile,
              first_name: formProfileData.first_name,
              last_name: formProfileData.last_name,
              birthday: formProfileData.birthday / 1000,
            }
          };

          profileResult = await updateProfile(updateData).unwrap();
          setCreatedProfile(profileResult);
        }
      } else {
        const data: Partial<ProfileCreateRequest> = {
          first_name: formProfileData.first_name,
          last_name: formProfileData.last_name,
          birthday: formProfileData.birthday / 1000,
        };

        profileResult = await createProfile(data as ProfileCreateRequest).unwrap();
        if (!profileResult) {
          throw new Error("Failed to create formProfileData");
        }
        setCreatedProfile(profileResult);
      }

      const paymentData = await createPaymentLink({
        option_id: PaymentOptionType.AdditionSubscription,
        entity_id: profileResult.id,
      }).unwrap();
      if (!isCreatePaymentError) {
        webApp.openInvoice(paymentData.link, (event: any) => {
          if (event === PaymentStatus.Paid) {
            navigate(`/profile/${profileResult.id}`);
          }
        });
      }

    } catch (err) {
      if (err instanceof Error) {
        handleError(err, err.message);
      } else {
        handleError(err, "An unexpected error occurred");
      }
    }
  }

  if (isProfilesLoading) return <div/>

  return (
    <div>
      <div className="flex flex-col min-h-screen px-5 constellation">

        <h1 className="text-3xl font-bold pt-8 mb-3 text-center">
          Новый астро-профиль
        </h1>

        <ProfileForm value={formProfileData} onChange={handleChange} invalidFields={invalidFields}/>
      </div>
    </div>
  )
}

export default ProfileCreatePage
