import React, { useEffect } from "react";
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ThemeProvider } from "context/theme.provider";
import { TelegramProvider } from "./context/telegram.provider";
import Oops from "widgets/components/oops";
import App from "App";
import { DrawerProvider } from "./context/drawer.context";
import * as Sentry from "@sentry/react";
import { store } from "./app/store";

import "app/i18n";
import "assets/theme/main.scss";


if (window._env_?.SENTRY && !!window._env_?.SENTRY_DSN) {
  Sentry.init({
    environment: process.env.REACT_APP_ENV,
    dsn: window._env_.SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    release: window._env_.SENTRY_RELEASE,
    dist: window._env_.SENTRY_RELEASE,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const container = document.getElementById("root")!;
const root = createRoot(container);

let appReady = Promise.resolve();

// Enable API mocking only in mocks
if (process.env.REACT_APP_ENV === "mocks") {
  const { worker } = require("./mocks/browser");
  appReady = worker.start({
    onUnhandledRequest: "bypass",
    serviceWorker: {
      /**
       * Use a custom Service Worker script URL to resolve
       * the mock worker served by Codesandbox.
       * @note You DO NOT need this in your application.
       * @see https://mswjs.io/docs/api/setup-worker/start#serviceworker
       */
      url: "/mockServiceWorker.js"
    }
  })
}

const getBasename = window._env_?.BASENAME || "/"

appReady
  .then(() => {
    root.render(
    // <React.StrictMode>
      <Sentry.ErrorBoundary fallback={<Oops/>}>
        <BrowserRouter basename={getBasename}>
          <Provider store={store}>
            <TelegramProvider>
              <ThemeProvider>
                <DrawerProvider>
                  <App/>
                </DrawerProvider>
              </ThemeProvider>
            </TelegramProvider>
          </Provider>
        </BrowserRouter>
      </Sentry.ErrorBoundary>
    // </React.StrictMode>
    );
  });
