import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import "./style.css";

export interface SearchOption {
  value: string;
  label: string;
}

export interface SearchProps {
  options: SearchOption[];
  value?: string;
  onChange?: (selected: SearchOption) => void;
  renderOption?: (option: SearchOption, isActive: boolean) => React.ReactNode;
  onSearch?: (searchTerm: string) => void;
  debounceTime?: number;
  placeholder?: string;
}

const Search: FC<SearchProps> = ({
  options,
  value,
  onChange,
  renderOption,
  onSearch,
  debounceTime = 300,
  placeholder = "Поиск...",
}) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const [ searchTerm, setSearchTerm ] = useState(value || "");
  const [ selected, setSelected ] = useState<SearchOption | undefined>(
    options.find((opt) => opt.value === value)
  );

  const debounceTimerRef = useRef<NodeJS.Timeout>();

  const debouncedSearch = useCallback((term: string) => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    debounceTimerRef.current = setTimeout(() => {
      onSearch?.(term);
    }, debounceTime);
  }, [ debounceTime, onSearch ]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchTerm(term);
    debouncedSearch(term);
    setIsOpen(true);
  };

  const handleOptionSelect = (option: SearchOption) => {
    setSelected(option);
    setSearchTerm("");
    setIsOpen(false);
    onChange?.(option);
  };

  const searchRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    if (value !== undefined && value !== selected?.value) {
      const newSelected = options.find((opt) => opt.value === value);
      setSelected(newSelected);
    }
  }, [ value, options ]);

  useEffect(() => {
    setSearchTerm(value || "");
  }, [ value ]);

  return (
    <div className="search" ref={searchRef}>
      <div className="search-input-container">
        <input
          type="text"
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder={placeholder}
        />
      </div>

      {isOpen && (
        <div className="search-content">
          {options.length > 0 ? (
            <ul className="search-list">
              {options.map((option, idx) => {
                const isActive = option.value === selected?.value;
                return (
                  <li
                    key={idx}
                    className={`search-item ${isActive ? "active" : ""}`}
                    onClick={() => handleOptionSelect(option)}
                  >
                    {renderOption ? renderOption(option, isActive) : option.label}
                  </li>
                );
              })}
            </ul>
          ) : (
            searchTerm.length >= 2 && (
              <div className="search-empty">
                Совпадений нет
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default Search;