import { api } from "./api"
import { Zodiac, ZodiacElement } from "../types/zodiac";
import {
  DailyCommon,
  DailyPersonal,
  DailyZodiac,
  LunarGrowthStage,
  ProfileCompatibilities,
  Recommendations,
  SunInfluence,
  ZodiacPlanet,
  ZodiacStar,
  ZodiacSystem,
} from "../types/astrology";

function getDailyCommonFromResponse(src: DailyCommonResponse): DailyCommon {
  return {
    ...src,
  }
}

function getDailyPersonalFromResponse(src: DailyPersonalResponse): DailyPersonal {
  return {
    ...src,
  }
}

function getDailyZodiacFromResponse(src: DailyZodiacResponse): DailyZodiac {
  return {
    ...src,
  }
}

export type DailyCommonResponse = {
  sun_influence: SunInfluence;
  zodiac_planet: ZodiacPlanet;
  lunar_growth_stage: LunarGrowthStage;
  zodiac_element: ZodiacElement;
}

export type DailyPersonalRequest = {
  profile_id: string
  date?: string // YYYY-MM-DD
}

export interface DailyPersonalResponse {
  profile_id: string;
  first_name: string;
  last_name: string;
  status: string;
  zodiac: Zodiac;
  date: number;
  start_period: number;
  end_period: number;
  zodiac_planet: ZodiacPlanet;
  happy_numbers: number[];
  horoscope: string;
  zodiacs: ZodiacSystem[];
  recommendations?: Recommendations;
  compatibilities?: ProfileCompatibilities
}

export type DailyZodiacRequest = {
  zodiac: string
  date?: string // YYYY-MM-DD
}

export type DailyZodiacResponse = {
  zodiac: Zodiac;
  date: number;
  status: string;
  start_period: number;
  end_period: number;
  zodiac_planet: ZodiacPlanet;
  horoscope: string;
  description: string;
  zodiac_star: ZodiacStar;
  tropical_zodiac: ZodiacSystem;
  sidereal_zodiac: ZodiacSystem;
}

function filterUndefinedParams(params: Record<string, any>): Record<string, any> | undefined {
  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([ _, value ]) => value !== undefined)
  );
  return Object.keys(filteredParams).length > 0 ? filteredParams : undefined;
}

export const astrologyApi = api.injectEndpoints({
  endpoints: (build) => ({

    dailyCommonFetch: build.query<DailyCommonResponse, void>({
      query: () => ({
        url: "v1/astro/daily",
        method: "GET",
      }),
      transformResponse: (response: DailyCommonResponse): DailyCommon => {
        return getDailyCommonFromResponse(response);
      },
    }),

    dailyPersonalFetch: build.query<DailyPersonalResponse, DailyPersonalRequest>({
      query: ({ profile_id, date }) => ({
        url: `v1/astro/daily/profile/${profile_id}`,
        method: "GET",
        params: filterUndefinedParams({ date })
      }),
      transformResponse: (response: DailyPersonalResponse): DailyPersonal => {
        return getDailyPersonalFromResponse(response);
      },
    }),

    dailyZodiacFetch: build.query<DailyZodiacResponse, DailyZodiacRequest>({
      query: ({ zodiac, date }) => ({
        url: `v1/astro/daily/zodiac/${zodiac}`,
        method: "GET",
        params: filterUndefinedParams({ date })
      }),
      transformResponse: (response: DailyZodiacResponse): DailyZodiac => {
        return getDailyZodiacFromResponse(response);
      },
    }),

  }),
})

export const {
  useDailyCommonFetchQuery,
  useDailyPersonalFetchQuery,
  useDailyZodiacFetchQuery
} = astrologyApi

export const {
  endpoints: {
    dailyCommonFetch,
    dailyPersonalFetch,
    dailyZodiacFetch,
  },
} = astrologyApi

