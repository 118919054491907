import { FC } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import { useAccount } from "../../hooks/useAccount"
import ScreenHello, { ScreenHelloPath } from "./screens/screenHello";
import ScreenProfile, { ScreenProfilePath } from "./screens/screenProfile";
import { RouteGroup } from "routes";
import ScreenSettings, { ScreenSettingsPath } from "./screens/screenSettings";
import ScreenBirthday, { ScreenBirthdayPath } from "./screens/screenBirthday";
import ScreenZodiacs, { ScreenZodiacsPath } from "./screens/screenZodiacs";
import ScreenDone, { ScreenDonePath } from "./screens/screenDone";
import { FormProvider } from "../../context/form.context";
import { getZodiacByDate, Zodiac } from "../../app/types/zodiac";

const FeaturePrefix = "welcome"

export const FeatureWelcomePath = (screen: string) => {
  return "/" + [ FeaturePrefix, screen ].join("/")
}

const defaultData = Date.UTC(new Date().getFullYear() - 20, 0, 1)

export type FormData = {
  first_name: string;
  last_name: string;
  birthday: number;
  report_daily_time: string;
  zodiacs: Zodiac[];
  isEditTime: boolean;
  country?: string;
  city?: string;
  street?: string;
  latitude?: number;
  longitude?: number;
}

const defaultFormData: FormData = {
  first_name: "",
  last_name: "",
  birthday: Date.UTC(new Date().getFullYear() - 20, 0, 1),
  report_daily_time: "08:00",
  zodiacs: [ getZodiacByDate(new Date(defaultData)) ],
  isEditTime: false,
  country: "",
  city: "",
  street: "",
  latitude: 0,
  longitude: 0
}

const FeatureWelcome: FC = () => {

  const auth = useAccount()
  const location = useLocation()

  if (!auth.session) return <Navigate to="/loading" state={{ from: location }}/>

  const routes: RouteGroup[] = [
    {
      layout: FeaturePrefix,
      pages: [
        {
          path: ScreenHelloPath,
          element: <ScreenHello/>,
        },
        {
          path: ScreenProfilePath,
          element: <ScreenProfile/>,
        },
        {
          path: ScreenBirthdayPath,
          element: <ScreenBirthday/>
        },
        {
          path: ScreenZodiacsPath,
          element: <ScreenZodiacs/>,
        },
        {
          path: ScreenSettingsPath,
          element: <ScreenSettings/>,
        },
        {
          path: ScreenDonePath,
          element: <ScreenDone/>
        },
      ]
    }
  ];

  return (
    <FormProvider initialData={defaultFormData}>
      <Routes>
        {routes.map(
          ({ layout, pages }) =>
            layout === FeaturePrefix &&
            pages.map(({ path, element }) => (
              <Route path={path} element={element}/>
            ))
        )}
      </Routes>
    </FormProvider>
  )
}

export default FeatureWelcome;
