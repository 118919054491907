import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./style.css";

const hours = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, "0"));

const createEmptyArray = (size: number) => Array.from({ length: size }, () => null);

interface TimePickerProps {
  label: string;
  value?: string; // формат "HH:MM"
  onChange?: (time: string) => void;
  isOpen?: boolean;
  alwaysOpen?: boolean;
  alwaysTimeShowOpen?: boolean;
  minuteInterval?: 1 | 5 | 10 | 15 | 30;
}

const TimePicker: React.FC<TimePickerProps> = ({
  label,
  value,
  onChange,
  isOpen = false,
  alwaysOpen = false,
  alwaysTimeShowOpen = false,
  minuteInterval = 15,
}) => {
  const [ isOpenValue, setIsOpenValue ] = useState(alwaysOpen || isOpen);
  const [ selectedTime, setSelectedTime ] = useState(() => {
    const [ hour, minute ] = value ? value.split(":") : [ "08", "00" ];
    return { hour, minute };
  });

  const minutes = Array.from(
    { length: 60 / minuteInterval },
    (_, i) => (i * minuteInterval).toString().padStart(2, "0")
  );

  const scrollTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isScrollingRef = useRef(false);
  const wheelContainerRefs = useRef<(HTMLDivElement | null)[]>([]);
  const initializedRef = useRef(false);

  const toggleDropdown = () => !alwaysOpen && setIsOpenValue(prev => !prev);

  const handleScroll = (
    e: React.UIEvent<HTMLDivElement>,
    type: "hour" | "minute",
    items: string[]
  ) => {
    const target = e.currentTarget as HTMLDivElement;
    const itemHeight = 40;
    const scrollTop = target.scrollTop;
    const index = Math.round(scrollTop / itemHeight);

    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current);
    }

    isScrollingRef.current = true;

    if (index >= 0 && index < items.length) {
      const newTime = {
        ...selectedTime,
        [type]: items[index]
      };
      setSelectedTime(newTime);
    }

    scrollTimeoutRef.current = setTimeout(() => {
      const closestIndex = Math.round(target.scrollTop / itemHeight);
      target.scrollTo({
        top: closestIndex * itemHeight,
        behavior: "smooth",
      });

      if (closestIndex >= 0 && closestIndex < items.length) {
        const finalTime = {
          ...selectedTime,
          [type]: items[closestIndex]
        };
        setSelectedTime(finalTime);
        onChange?.(`${finalTime.hour}:${finalTime.minute}`);
      }
      isScrollingRef.current = false;
    }, 300);
  };

  const scrollToSelected = (behavior: ScrollBehavior = "smooth") => {
    const itemHeight = 40;
    const hourIndex = hours.indexOf(selectedTime.hour);
    const minuteIndex = minutes.indexOf(selectedTime.minute);

    if (wheelContainerRefs.current.length > 0) {
      wheelContainerRefs.current[0]?.scrollTo({ top: hourIndex * itemHeight, behavior });
      wheelContainerRefs.current[1]?.scrollTo({ top: minuteIndex * itemHeight, behavior });
    }
  };

  useLayoutEffect(() => {
    if ((alwaysTimeShowOpen || isOpenValue) && !initializedRef.current) {
      scrollToSelected("instant");
      initializedRef.current = true;
    }
  }, [ isOpenValue, alwaysTimeShowOpen ]);

  useEffect(() => {
    if (isOpenValue && !isScrollingRef.current) {
      scrollToSelected("smooth");
    }
  }, [ selectedTime ]);

  useEffect(() => {
    if (value) {
      const [ hour, minute ] = value.split(":");
      setSelectedTime({ hour, minute });
      if (isOpenValue || alwaysTimeShowOpen) {
        scrollToSelected("instant");
      }
    }
  }, [ value ]);

  const renderWheelList = (items: string[], selectedItem: string, type: "hour" | "minute") => {
    return (
      <div
        key={type}
        className="wheel"
        ref={el => {
          wheelContainerRefs.current[type === "hour" ? 0 : 1] = el;
        }}
        onScroll={(e) => handleScroll(e, type, items)}
      >
        <div className="wheel-container">
          <ul className="wheel-list">
            {createEmptyArray(1).map((_, idx) => <li key={idx} style={{ height: "40px" }}></li>)}
            {items.map((item, idx) => (
              <li
                key={idx}
                className={`wheel-item ${selectedItem === item ? "active" : ""}`}
                style={{ height: "40px" }}
              >
                {item}
              </li>
            ))}
            <li style={{ height: "40px" }}></li>
          </ul>
        </div>
      </div>
    );
  };

  if (alwaysTimeShowOpen) {
    return (
      <div className="timepicker-picker">
        <div className="dropdown-content block">
          <div className="grid grid-cols-2 gap-2 justify-center w-full">
            {renderWheelList(hours, selectedTime.hour, "hour")}
            {renderWheelList(minutes, selectedTime.minute, "minute")}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="timepicker-picker">
      <div
        onClick={toggleDropdown}
        className={`
          timepicker-picker-header 
          ${alwaysOpen ? "" : "cursor-pointer"}
          ${!isOpenValue ? "" : "border-b border-gray-600 pb-2"}
        `}>
        <span className="title me-5">{label}</span>
        <span className="action">
          {`${selectedTime.hour}:${selectedTime.minute}`}
        </span>
      </div>

      <div className={`dropdown-content ${isOpenValue ? "block" : "hidden"}`}>
        <div className="grid grid-cols-2 gap-2 w-48">
          {renderWheelList(hours, selectedTime.hour, "hour")}
          {renderWheelList(minutes, selectedTime.minute, "minute")}
        </div>
      </div>
    </div>
  );
};

export default TimePicker;
