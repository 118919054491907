import React from "react";
import { useNavigate } from "react-router-dom";

const BottomBar: React.FC = () => {

  const navigate = useNavigate();

  return (
    <>
      <div className="shadow-lg bg-nav-menu h-24 grid grid-cols-3 items-center">
        <div className="flex flex-col items-center cursor-pointer" onClick={() => navigate("/")}>
          <img src="/img/menu/home.svg" alt="Home" className="mb-1 w-8 h-8"/>
          <span className="">Главная</span>
        </div>
        <div className="flex flex-col items-center cursor-pointer" onClick={() => navigate("/astro")}>
          <img src="/img/menu/compatibility.svg" alt="Compatibility" className="mb-1 w-8 h-8"/>
          <span className="">Нейро астрология</span>
        </div>
        <div className="flex flex-col items-center cursor-pointer" onClick={() => navigate("#")}>
          <img src="/img/menu/knowledge_base.svg" alt="Knowledge Base" className="mb-1 w-8 h-8"/>
          <span className="">База знаний</span>
        </div>
      </div>
    </>
  );
};

export default BottomBar;
