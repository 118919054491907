import { Zodiac, ZodiacElement } from "./zodiac";
import { EfficiencyGroup } from "./efficiency";

export type DailyCommon = {
  sun_influence: SunInfluence;
  zodiac_planet: ZodiacPlanet;
  lunar_growth_stage: LunarGrowthStage;
  zodiac_element: ZodiacElement;
}

export type DailyPersonal = {
  profile_id: string;
  first_name: string;
  last_name: string;
  status: string;
  zodiac: Zodiac;
  date: number;
  start_period: number;
  end_period: number;
  zodiac_planet: ZodiacPlanet;
  happy_numbers: number[];
  horoscope: string;
  zodiacs: ZodiacSystem[];
  recommendations?: Recommendations;
  compatibilities?: ProfileCompatibilities
}

export type DailyZodiac = {
  zodiac: Zodiac;
  date: number;
  status: string;
  start_period: number;
  end_period: number;
  zodiac_planet: ZodiacPlanet;
  horoscope: string;
  description: string;
  zodiac_star: ZodiacStar;
  tropical_zodiac: ZodiacSystem;
  sidereal_zodiac: ZodiacSystem;
}

export type ProfileCompatibilities = {
  mood: ProfileCompatibility
  love: ProfileCompatibility
  work: ProfileCompatibility
}

export type ProfileCompatibility = {
  group: EfficiencyGroup;
  zodiac: Zodiac;
  percentage: number;
}

export type ZodiacStar = {
  name: string;
  position: number;
  star_numbers: number;
}

export type ZodiacSystem = {
  start_period: number;
  end_period: number;
}

export type Recommendations = {
  positives: string[];
  negatives: string[];
}

export type PlanetInfluence = {
  current_zodiac: Zodiac;
  next_zodiac: Zodiac;
  start_period: number;
  end_period: number;
  position: number;
  planet: string;
  influence: string;
}

export type ZodiacPlanet = {
  name: string;
  phase: PlanetPhase;
  positive_effect: string
  negative_effect: string
}

export type ZodiacMoon = {
  phase: MoonPhase;
  influence: string;
}

export type ZodiacAscendant = {
  zodiac: Zodiac;
  appearance: string;
}

export type SunInfluence = {
  current_zodiac: Zodiac;
  next_zodiac: Zodiac;
  start_period: number;
  end_period: number;
  position: number;
  planet: string;
  influence: string;
}

export type LunarGrowthStage = {
  phase: MoonPhase;
  position: number;
  start_period: number;
  end_period: number;
}

export interface UniverseEntityPosition {
  id: string;
  body_id: UniverseEntity;
  date: number;
  degrees: number;
  zodiac: Zodiac;
}

export interface ZodiacAspect {
  type: AspectType;
  pos1: UniverseEntityPosition;
  pos2: UniverseEntityPosition;
}

export enum PlanetPhase {
  DIRECT = "DIRECT",                    // Прямое движение
  RETROGRADE = "RETROGRADE",                // Ретроградное движение
  STATIONARY = "STATIONARY",                // Стационарное состояние
}

export enum AspectType {
  CONJUNCTION = "CONJUNCTION",    // соединение 0°
  SEXTILE = "SEXTILE",          // секстиль 60°
  SQUARE = "SQUARE",            // квадрат 90°
  TRINE = "TRINE",              // трин 120°
  OPPOSITION = "OPPOSITION",     // оппозиция 180°
  QUINCUNX = "QUINCUNX",        // квинконс 150°
  SEMISEXTILE = "SEMISEXTILE"   // полусекстиль 30°
}

export enum UniverseEntity {
  SUN = "SUN",
  MERCURY = "MERCURY",
  VENUS = "VENUS",
  MOON = "MOON",
  MARS = "MARS",
  JUPITER = "JUPITER",
  SATURN = "SATURN",
  URANUS = "URANUS",
  NEPTUNE = "NEPTUNE",
  PLUTO = "PLUTO",
}

export type HouseType = keyof typeof House;

export enum House {
  FIRST_HOUSE = "FIRST_HOUSE",
  SECOND_HOUSE = "SECOND_HOUSE",
  THIRD_HOUSE = "THIRD_HOUSE",
  FOURTH_HOUSE = "FOURTH_HOUSE",
  FIFTH_HOUSE = "FIFTH_HOUSE",
  SIXTH_HOUSE = "SIXTH_HOUSE",
  SEVENTH_HOUSE = "SEVENTH_HOUSE",
  EIGHTH_HOUSE = "EIGHTH_HOUSE",
  NINTH_HOUSE = "NINTH_HOUSE",
  TENTH_HOUSE = "TENTH_HOUSE",
  ELEVENTH_HOUSE = "ELEVENTH_HOUSE",
  TWELFTH_HOUSE = "TWELFTH_HOUSE",
}

export enum MoonPhase {
  NEW_MOON = 1,
  WAXING_CRESCENT = 2,
  FIRST_QUARTER = 3,
  WAXING_GIBBOUS = 4,
  FULL_MOON = 5,
  WANING_GIBBOUS = 6,
  THIRD_QUARTER = 7,
  WANING_CRESCENT = 8,
}

export type Element = "air" | "water" | "fire" | "earth";