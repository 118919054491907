import React from "react";
import { PaymentOption, PaymentType } from "app/types/payment";
import { usePaymentOptionsListQuery } from "app/services/payment";
import { useAccountReferralStatsFetchQuery } from "app/services/account";

type PaymentFormProps = {
  type: PaymentType;
  description?: string;
  buttonText?: string;
  onSubmit: (o: PaymentOption) => void;
};

const PROVIDER_PRIORITY: {[key: string]: number} = {
  telegram: 1,
  aistro: 2,
  // Add future providers here with higher numbers
};

function sortByProvider(a: PaymentOption, b: PaymentOption): number {
  const priorityA = PROVIDER_PRIORITY[a.provider] || 999;
  const priorityB = PROVIDER_PRIORITY[b.provider] || 999;
  return priorityA - priorityB;
}

function getCurrencyName(c: string) {
  switch (c) {
    case "xtr":
      return "Stars"
    case "fens":
      return "Fens"
    default:
      return "";
  }
}

export const PaymentForm: React.FC<PaymentFormProps> = ({
  type,
  onSubmit,
  description,
  buttonText,
}) => {
  const [ selectedPayment, setSelectedPayment ] = React.useState<PaymentOption | undefined>();
  const { data: paymentOptionList } = usePaymentOptionsListQuery({
    type: type,
  });

  const { data: referralStats } = useAccountReferralStatsFetchQuery(undefined, {
    skip: !paymentOptionList?.some(option => option.provider === "aistro")
  });

  const isPaymentDisabled = (option: PaymentOption) => {
    if (option.provider !== "aistro") return false;
    if (!referralStats) return true;

    const availablePoints = referralStats.total - referralStats.used;
    return availablePoints < option.cost;
  };

  const sortedPaymentOptions = React.useMemo(() => {
    return paymentOptionList?.slice().sort(sortByProvider);
  }, [ paymentOptionList ]);

  return (
    <div>
      <div className="flex flex-col gap-5 items-center mb-10 w-full">
        <div className="flex flex-row gap-5 justify-center w-full">
          {sortedPaymentOptions?.map((currency) => {
            const paymentName = getCurrencyName(currency.currency);
            const isDisabled = isPaymentDisabled(currency);

            return (
              <div key={currency.id} className="flex flex-col items-center">
                <div
                  className={`bg-card-gradient border rounded-xl w-32 h-24 flex flex-col items-center justify-center 
                    ${isDisabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}
                    ${selectedPayment?.id === currency.id ? "border-[#FFD700]" : "border-transparent"}`}
                  onClick={() => !isDisabled && setSelectedPayment(currency)}
                >
                  <img className="w-14" src={`/ico/pay-${paymentName.toLowerCase()}.svg`} alt=""/>
                  {isDisabled && currency.provider === "aistro" && (
                    <div className="text-xs py-2 text-red-500">
                      Недоступно
                    </div>
                  )}
                </div>
                <span className={`mt-2 ${
                  selectedPayment?.id === currency.id ? "text-[#FFD700]" : "text-[#AFAFDE]"
                }`}>
                  {currency.cost} {paymentName}
                </span>
              </div>
            );
          })}
        </div>
      </div>

      {!!description && (
        <div className="mb-5 text-center text-xs text-[#AFAFDE] w-full flex justify-center">
          <div className="w-2/3">
            {description}
          </div>
        </div>
      )}

      <div className="flex justify-center items-center">
        <div
          className={`flex justify-center items-center rounded-xl p-4 bg-card-gradient mb-5 
            ${!selectedPayment || isPaymentDisabled(selectedPayment) ? "opacity-50 cursor-not-allowed" : "cursor-pointer"} w-4/5`}
          onClick={() => selectedPayment && !isPaymentDisabled(selectedPayment) && onSubmit(selectedPayment)}
        >
          <div className="mb-1 flex justify-center items-center">
            <img src="/ico/arrow-up.svg" alt=""/>
          </div>
          <div className="px-5 font-bold text-gold-gradient flex items-center justify-center">
            {buttonText || "Заказать"}
          </div>
          <div className="mb-1 flex justify-center items-center">
            <img src="/ico/arrow-up.svg" alt=""/>
          </div>
        </div>
      </div>
    </div>
  );
}; 