import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Drawer, useDrawer } from "context/drawer.context";
import { useTelegram } from "context/telegram.provider";
import { ProfileCreateRequest, useProfileCreateMutation, useProfileListQuery } from "app/services/profile";
import AstroProfileForm, { AstroProfile } from "widgets/forms/astroprofile";
import { formatDate, handleError } from "utils";
import { useTranslation } from "react-i18next";

const NatalChartCreatePage = () => {

  const navigate = useNavigate();
  const { t } = useTranslation()

  const { setBackButtonVisible, setMainButtonVisible, setBackButtonOnClick } = useTelegram();
  const { openDrawer, closeDrawer } = useDrawer();
  const [ selectedProfile, setSelectedProfile ] = useState<AstroProfile>();
  const [ selectFn, setSelectFn ] = useState<(profile: AstroProfile) => void>();

  const {
    data: profiles,
  } = useProfileListQuery();
  const {
    data: histories,
  } = useProfileListQuery({ natal_chart: true });
  const [ createProfile ] = useProfileCreateMutation();

  // Configure navigation routing
  useEffect(() => {
    setMainButtonVisible(false);
    setBackButtonVisible(true);
    setBackButtonOnClick(() => navigate("/astro"));
  }, []);

  useEffect(() => {
    if (!!profiles?.length && !selectedProfile) {
      const p = profiles.find(p => p.is_default)
      if (!p) return
      const data: AstroProfile = {
        id: p.id,
        name: `${p.first_name || ""} ${p.last_name || ""}`.trim(),
        birthday: p.birthday,
        country: p.country,
        city: p.city,
        street: p.street,
        latitude: p.latitude,
        longitude: p.longitude,
        zodiac: p.zodiac
      };
      setSelectedProfile(data)
    }
  }, [ profiles ]);

  const handleSelect = (profile?: AstroProfile) => {
    setSelectedProfile(profile)

    const newSelectFn = (profile: AstroProfile) => {
      setSelectedProfile(profile);
      closeDrawer();
    };

    setSelectFn(() => newSelectFn);
    openDrawer();
  };

  const submit = async () => {
    if (!selectedProfile) return

    if (!selectedProfile?.id) {
      try {
        const parts = selectedProfile.name.split(" ")
        const data: Partial<ProfileCreateRequest> = {
          first_name: parts[0] || "",
          last_name: parts.slice(1).join(" ") || "",
          birthday: selectedProfile.birthday / 1000,
          country: selectedProfile.country,
          city: selectedProfile.city,
          street: selectedProfile.street,
          latitude: selectedProfile.latitude,
          longitude: selectedProfile.longitude,
          natal_chart_requested: true,
        };

        const profileResult = await createProfile(data as ProfileCreateRequest).unwrap()
        if (!profileResult) {
          throw new Error("NatalChart: Failed to create profile")
        }

        navigate(`/profile/${profileResult.id}/natal_chart`)

      } catch (err) {
        if (err instanceof Error) {
          handleError(err, err.message)
        } else {
          handleError(err, "An unexpected error occurred")
        }
      }

    } else {
      navigate(`/profile/${selectedProfile.id}/natal_chart`)
    }
  }

  return (
    <div>
      <div className="mb-10">
        <div className="flex flex-col items-center justify-center mt-8">
          <img className="w-12 h-12 mx-auto" src="/ico/zodicus.svg" alt=""/>
        </div>
        <h1 className="text-3xl font-bold py-4 text-center text-gold-gradient capitalize">
          {t("features.natalchart.create.title")}
        </h1>
        <div className="text-center">
          <p className="text-tg-theme-subtitle">
            {t("features.natalchart.create.summary")}
          </p>
        </div>
      </div>

      <div className="px-10 my-10">
        <ProfileSection
          profile={selectedProfile}
          onSelect={handleSelect}
        />
      </div>

      {!!selectedProfile && (
        <div className="px-5 my-10 flex justify-center items-center">
          <div
            className="flex justify-center items-center rounded-xl p-4 bg-card-gradient mb-5 cursor-pointer w-4/5"
            onClick={submit}
          >
            <div className="mb-1 flex justify-center items-center">
              <img src="/ico/arrow-right.svg" alt=""/>
            </div>
            <div className="px-5 font-bold flex-col text-gold-gradient flex items-center justify-center">
              {t("features.natalchart.create.requestButton")}
            </div>
            <div className="mb-1 flex justify-center items-center">
              <img src="/ico/arrow-left.svg" alt=""/>
            </div>
          </div>
        </div>
      )}

      <div className="mb-1 flex justify-center">
        <img src="/ico/double-arrow-down.svg" alt=""/>
      </div>

      <div className="mx-10 my-10 flex flex-col">
        <div className="w-full text-center mb-5">
          <h2 className="text-[#AFAFDE]">
            {t("features.natalchart.create.history")}
          </h2>
        </div>

        {histories?.map((profile, index) => {
          return (
            <div key={index}
              className="flex justify-between items-center rounded-xl mt-5 px-5 py-3 mb-5 bg-card-gradient cursor-pointer"
              onClick={() => navigate(`/profile/${profile.id}/natal_chart`)}
            >
              <img src={"/img/constellation/aries.svg"} alt={""} className="w-6 h-6 rounded-full me-4"/>
              <div className="text-gold-gradient w-full">
                {`${profile.first_name || ""} ${profile.last_name || ""}`.trim()} {formatDate(new Date(profile.birthday))}
              </div>
              <div className="flex justify-end">
                <img src="/ico/arrow-right.svg" alt=""/>
              </div>
            </div>
          )
        })}
      </div>

      <Drawer title={t("features.natalchart.create.drawerTitle")}>
        <div className="px-5">
          <AstroProfileForm
            profiles={profiles || []}
            value={selectedProfile}
            onSelect={(p) => selectFn ? selectFn(p) : null}/>
        </div>
      </Drawer>
    </div>
  )
}

interface ProfileSectionProps {
  profile?: AstroProfile;
  onSelect: (profile?: AstroProfile) => void;
}

const ProfileSection: React.FC<ProfileSectionProps> = ({ profile, onSelect }) => {
  const { t } = useTranslation();

  if (!profile) {
    return (
      <div className="px-5 my-10">
        <div className="flex flex-col items-center justify-center border-2 border-dashed rounded-xl p-10">
          <div className="text-center mb-6">
            {t("features.natalchart.create.profileSection.title")}
          </div>
          <button
            className="px-6 py-2 rounded-xl bg-card-gradient text-tg-theme-section-header"
            onClick={() => onSelect()}
          >
            {t("common.button.change")}
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="mb-5 flex flex-col items-center justify-center mt-8">
        <img className="w-32 h-32 mx-auto" src="/img/constellation/gemini.svg" alt=""/>
        <h1 className="text-base py-2 text-center text-gold-gradient">
          {profile.name}
        </h1>
        <p className="text-xs text-gold-gradient">
          {[ formatDate(new Date(profile.birthday)), profile.country, profile.city ].filter(Boolean).join(", ") || ""}
        </p>
      </div>

      <div className="w-full flex justify-center">
        <button
          className="px-6 py-2 rounded-xl bg-card-gradient text-tg-theme-section-header"
          onClick={() => onSelect(profile)}
        >
          {t("common.button.change")}
        </button>
      </div>
    </>
  );
};

export default NatalChartCreatePage