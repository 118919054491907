import React, { FC } from "react"

export interface SubscriptionFormProps {
  onClick: () => void
}

const ProfileSubscriptionForm: FC<SubscriptionFormProps> = ({ onClick }) => {
  return (
    <div className="flex flex-col gap-8">

      <div className="text-center px-5">
        Рекомендуем вам обновить аккаунт чтобы получить более подробный уровень доступа к астрологическому профилю
      </div>

      <div
        className="flex justify-center items-center rounded-xl p-4 bg-card-gradient mb-5 cursor-pointer"
        onClick={onClick}>

        <div className="mb-1 flex justify-center">
          <img src="/ico/arrow-up.svg" alt=""/>
        </div>
        <div className="px-5 font-bold text-base text-tg-theme-section-header">
          Улучшить уровень
        </div>
        <div className="mb-1 flex justify-center">
          <img src="/ico/arrow-up.svg" alt=""/>
        </div>

      </div>

      <div className="flex flex-col items-center justify-center w-full">
        <div className="text-center mb-5">
          Вам откроются следующие разделы:
        </div>
        <div className="inline-block text-left">
          <div className="flex items-center gap-4 py-2">
            <img src="/ico/arrow-up.svg" alt=""/>
            <p>ежедневная совместимость</p>
          </div>
          <div className="flex items-center gap-4 py-2">
            <img src="/ico/arrow-up.svg" alt=""/>
            <p>система рекомендации</p>
          </div>
          <div className="flex items-center gap-4 py-2">
            <img src="/ico/arrow-up.svg" alt=""/>
            <p>календарь эффективности</p>
          </div>
          <div className="flex items-center gap-4 py-2">
            <img src="/ico/arrow-up.svg" alt=""/>
            <p>натальная карта</p>
          </div>
          <div className="flex items-center gap-4 py-2">
            <img src="/ico/arrow-up.svg" alt=""/>
            <p>дневная карта таро</p>
          </div>
        </div>
      </div>

    </div>
  )
}

export default ProfileSubscriptionForm
