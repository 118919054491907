import React, { ChangeEvent, useEffect, useState } from "react";
import Card from "widgets/components/card";
import DatePicker from "widgets/forms/datepicker";
import Search from "widgets/forms/search";
import { Location } from "app/types/location";
import { SearchOption } from "widgets/forms/search/search";
import { useLazyLocationListQuery } from "app/services/location";
import { Profile } from "app/types/profile";

interface ProfileFormProps {
  value?: Partial<Profile>;
  onChange?: (data: Partial<Profile>) => void;
  invalidFields?: string[];
}

const ProfileForm: React.FC<ProfileFormProps> = ({ value = {}, onChange, invalidFields = [] }) => {

  const [ profileValue, setProfileValue ] = useState<Partial<Profile>>(value);

  const [ locations, setLocations ] = useState<Location[]>([]);
  const [ triggerLocationSearch ] = useLazyLocationListQuery();

  useEffect(() => {
    setProfileValue(value)
  }, [ value ])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const patch = { ...profileValue, [e.target.name]: e.target.value }
    setProfileValue(patch)
    onChange?.(patch)
  }

  const handleBirthdayChange = (v: number) => {
    const patch = { ...profileValue, birthday: v }
    setProfileValue(patch)
    onChange?.(patch)
  }

  const handleSearch = (query: string) => {
    if (query.length >= 2) {
      triggerLocationSearch({ query })
        .unwrap()
        .then(setLocations);
    }
  };

  const handleLocationSelect = (selected: SearchOption) => {
    const selectedLocation = locations[Number(selected.value)];
    const patch = {
      ...profileValue,
      country: selectedLocation.country,
      city: selectedLocation.city,
      street: "",
      latitude: selectedLocation.latitude,
      longitude: selectedLocation.longitude,
    };
    setProfileValue(patch);
    onChange?.(patch);
  };

  const isFieldInvalid = (fieldName: string) => invalidFields.includes(fieldName);

  return (
    <>
      <div className="mb-2 px-0">
        <Card title={"First name"}>
          <div className="flex justify-center items-center min-h-22">
            <input
              type="text"
              name="first_name"
              className="w-full text-tg-theme-text bg-transparent border-transparent
                      focus:border-transparent focus:ring-0 focus:outline-none"
              value={profileValue.first_name || ""}
              onChange={handleChange}
            />
          </div>
        </Card>
        {isFieldInvalid("first_name") && <p className="text-red-500 text-xs ms-2">First name is required.</p>}
      </div>

      <div className="mb-2 px-0">
        <Card title={"Last name"}>
          <div className="flex justify-center items-center min-h-22">
            <input
              type="text"
              name="last_name"
              className="w-full text-tg-theme-text bg-transparent border-transparent
                focus:border-transparent focus:ring-0 focus:outline-none"
              value={profileValue.last_name || ""}
              onChange={handleChange}
            />
          </div>
        </Card>
        {isFieldInvalid("last_name") && <p className="text-red-500 text-xs ms-2">Last name is required.</p>}
      </div>

      <div className="mb-2 px-0">
        <div className="text-tg-theme-section-header text-xs uppercase ml-3 mb-2">
          Location
        </div>
        <Search
          options={locations?.map((location, index) => ({
            value: String(index),
            label: `${location.country}, ${location.city}`
          })) || []}
          value={profileValue.city ? `${profileValue.country}, ${profileValue.city}` : ""}
          onSearch={handleSearch}
          onChange={handleLocationSelect}
          debounceTime={500}
          placeholder="Поиск..."
        />
      </div>

      <div className="mb-2 px-0">
        <div className="text-tg-theme-section-header text-xs uppercase ml-3 mb-2">
          Date of birth
        </div>
        <div className="flex justify-center items-center min-h-22">
          <div className="flex-auto">
            <DatePicker
              value={profileValue.birthday}
              onChange={handleBirthdayChange}
              label="Select date"
              timeLabel="Знаешь во сколько родился?"
            />
          </div>
        </div>
        {isFieldInvalid("birthday") && <p className="text-red-500 text-xs ms-2">Date of birth is required.</p>}
      </div>

    </>
  )
}

export default ProfileForm
