import React from "react";
import { Zodiac } from "app/types/zodiac";

interface ZodiacListProps {
  selectedZodiacs?: Zodiac[];
  selectable?: boolean;
  onChange?: (selectedZodiacs: Record<Zodiac, boolean>) => void;
  onSelect?: (sing: Zodiac) => void;
  showOnlySelected?: boolean;
}

const ZodiacList: React.FC<ZodiacListProps> = ({
  selectedZodiacs,
  selectable = false,
  onChange,
  onSelect,
  showOnlySelected = false,
}) => {

  const handleSignClick = (zodiac: Zodiac) => {
    onSelect?.(zodiac);
    if (selectable && onChange) {
      const newSelectedZodiacs: Record<Zodiac, boolean> = Object.values(Zodiac).reduce((acc, s) =>
        ({ ...acc, [s]: selectedZodiacs?.includes(s) ?? false }), {} as Record<Zodiac, boolean>);
      newSelectedZodiacs[zodiac] = !newSelectedZodiacs[zodiac];
      onChange?.(newSelectedZodiacs);
    }
  };

  return (
    <div className="grid grid-cols-3 gap-4">
      {Object.values(Zodiac).map((zodiac) => {
        if (showOnlySelected && selectedZodiacs && !selectedZodiacs.includes(zodiac)) {
          return null;
        }
        const isSelected = selectedZodiacs?.includes(zodiac);
        const isMuted = selectedZodiacs && !showOnlySelected ? !isSelected : false;

        return (
          <div
            key={zodiac}
            onClick={() => handleSignClick(zodiac)}
            className={`
              aspect-square text-center cursor-pointer rounded-xl 
              flex flex-col items-center justify-evenly bg-card-gradient-stars
              ${selectable && isSelected ? "ring-2 ring-blue-500" : ""}
              ${isMuted ? "opacity-50" : "opacity-100"}
            `}
          >
            <div className="w-16 h-16 flex items-center justify-center bg-custom-radial">
              <img className="w-full h-full" src={`/img/constellation/${zodiac.toLowerCase()}.svg`} alt={zodiac}/>
            </div>
            <p className="mt-2 text-[#FCF0A1]">
              {zodiac.charAt(0) + zodiac.slice(1).toLowerCase()}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default ZodiacList;
