import { api } from "./api"
import { Location } from "app/types/location";

function getLocationFromResponse(src: LocationResponse): Location {
  return {
    ...src,
  }
}

export type LocationListFilter = {
  query: string
  locale?: string
}

export type LocationResponse = {
  name: string;
  country: string;
  countryCode: string;
  continentCode: string;
  timezone: string;
  latitude: number;
  longitude: number;
  city: string;
}

export type LocationListResponse = {
  items: LocationResponse[]
}

export const locationApi = api.injectEndpoints({
  endpoints: (build) => ({

    locationList: build.query<Location[], LocationListFilter | void>({
      query: (filter?: LocationListFilter) => ({
        url: "v1/location/search",
        method: "GET",
        params: filter,
      }),
      transformResponse: (response: LocationListResponse): Location[] => {
        return response.items.map(getLocationFromResponse);
      },
    }),

  }),
})

export const {
  useLocationListQuery,
  useLazyLocationListQuery,
} = locationApi

export const {
  endpoints: {
    locationList,
  },
} = locationApi

