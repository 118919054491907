import React, { FC } from "react";
import { useCompatibilityProfileListQuery } from "app/services/compatibility";
import { formatDate } from "utils";
import { getZodiac, Zodiac } from "app/types/zodiac";
import { CompatibilityProfile } from "app/types/compatibility";

export interface CompatibilityHistoryListProps {
  profile_id?: string
  onSelect?: (compatibility: CompatibilityProfile) => void
}

export const CompatibilityHistoryList: FC<CompatibilityHistoryListProps> = ({ profile_id, onSelect }) => {

  const params = profile_id ? { profile_id: profile_id } : undefined
  const {
    data: compatibilities,
  } = useCompatibilityProfileListQuery(params);

  return (
    <div className="space-y-4">
      {!compatibilities?.length && (
        <div className="w-full text-center mb-5">
          <p className=" text-[#AFAFDE]">нет истории</p>
        </div>
      )}

      {compatibilities?.map((compatibility, index) => {
        return (
          <div
            key={index}
            className="relative grid grid-cols-[1fr_auto_1fr] gap-4 items-center bg-card-gradient px-5 py-2
              rounded-lg cursor-pointer opacity-75"
            onClick={() => onSelect?.(compatibility)}>
            {compatibility.status === "unpaid" && (
              <div
                className="absolute -top-2 left-1/2 transform -translate-x-1/2 rounded-full bg-card-gradient p-1">
                <img src="/ico/locked.svg" alt="locked" className="w-4 h-4"/>
              </div>
            )}
            <div className="flex items-center min-w-0">
              <img
                src={`/img/constellation/${getZodiac(compatibility.profile1?.zodiac ?? Zodiac.ARIES).toLowerCase()}.svg`}
                alt={""}
                className="w-8 rounded-full mr-4 flex-shrink-0"/>
              <div className="text-[#AFAFDE] min-w-0">
                <div className="truncate">{compatibility.profile1.first_name} {compatibility.profile1.last_name}</div>
                <div>{formatDate(new Date(compatibility.profile1.birthday * 1000))}</div>
              </div>
            </div>

            <div className="text-[#AFAFDE]">x</div>

            <div className="flex items-center min-w-0 justify-end">
              <div className="text-[#AFAFDE] min-w-0">
                <div className="truncate">{compatibility.profile2.first_name} {compatibility.profile2.last_name}</div>
                <div>{formatDate(new Date(compatibility.profile2.birthday * 1000))}</div>
              </div>
              <img
                src={`/img/constellation/${getZodiac(compatibility.profile2?.zodiac ?? Zodiac.ARIES).toLowerCase()}.svg`}
                alt={""}
                className="w-8 rounded-full ml-4 flex-shrink-0"/>
            </div>
          </div>
        );
      })}
    </div>
  )
}

export default CompatibilityHistoryList