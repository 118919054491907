import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

interface DrawerContextType {
  isOpen: boolean;
  openDrawer: (name?: string) => void;
  closeDrawer: () => void;
  activeDrawer?: string;
}

const DrawerContext = createContext<DrawerContextType | undefined>(undefined);

export const useDrawer = () => {
  const context = useContext(DrawerContext);
  if (!context) {
    throw new Error("useDrawer must be used within a DrawerProvider");
  }
  return context;
};

export const DrawerProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const [ activeDrawer, setActiveSheetName ] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (isOpen) {
      window.Telegram?.WebApp?.disableVerticalSwipes();
    } else {
      window.Telegram?.WebApp?.enableVerticalSwipes?.();
    }

    return () => {
      window.Telegram?.WebApp?.enableVerticalSwipes?.();
    };
  }, [ isOpen ]);

  const openDrawer = (name?: string) => {
    setActiveSheetName(name);
    setIsOpen(true);
  };

  const closeDrawer = () => {
    setActiveSheetName(undefined);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        closeDrawer();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <DrawerContext.Provider value={{ isOpen, openDrawer, closeDrawer, activeDrawer }}>
      {children}
      {isOpen &&
        ReactDOM.createPortal(
          <div
            className="fixed inset-0 z-[10] bg-card-gradient bg-opacity-50 transition-opacity duration-300 opacity-75"
            onClick={closeDrawer}
          />,
          document.body
        )}
    </DrawerContext.Provider>
  );
};

interface DrawerProps {
  children: React.ReactNode;
  title?: string;
  name?: string;
  noscrollable?: boolean;
  onClose?: () => void;
  maxHeight?: string;
}

export const Drawer: React.FC<DrawerProps> = ({
  name,
  children,
  title,
  noscrollable,
  onClose,
  maxHeight = "90vh"
}) => {
  const { isOpen, closeDrawer, activeDrawer } = useDrawer();
  const [ shouldRender, setShouldRender ] = useState(false);
  const [ isAnimating, setIsAnimating ] = useState(false);

  // Track the starting Y position when drag begins
  const [ dragStartY, setDragStartY ] = useState<number | null>(null);
  // Track the current vertical translation of the bottom sheet
  const [ currentTranslateY, setCurrentTranslateY ] = useState(0);
  const bottomSheetRef = useRef<HTMLDivElement>(null);

  // Add new state for viewport height
  const [ viewportHeight, setViewportHeight ] = useState<string>(maxHeight);
  const [ keyboardHeight, setKeyboardHeight ] = useState(0);

  // Add effect to handle viewport changes
  useEffect(() => {
    const updateViewportHeight = () => {
      const vh = window.visualViewport?.height || window.innerHeight;
      const newKeyboardHeight = window.innerHeight - (window.visualViewport?.height || window.innerHeight);
      setKeyboardHeight(newKeyboardHeight);

      // Use full viewport height when keyboard is active, maxHeight when it's not
      if (newKeyboardHeight > 0) {
        setViewportHeight(`${vh}px`);
      } else {
        // Apply maxHeight constraint when keyboard is hidden
        let maxHeightPx = maxHeight;
        if (maxHeight.endsWith("vh")) {
          const percentage = parseInt(maxHeight) / 100;
          maxHeightPx = `${vh * percentage}px`;
        }
        setViewportHeight(maxHeightPx);
      }
    };

    updateViewportHeight();

    window.visualViewport?.addEventListener("resize", updateViewportHeight);
    window.addEventListener("resize", updateViewportHeight);

    return () => {
      window.visualViewport?.removeEventListener("resize", updateViewportHeight);
      window.removeEventListener("resize", updateViewportHeight);
    };
  }, [ maxHeight ]);

  useEffect(() => {
    if (isOpen && activeDrawer === name) {
      setCurrentTranslateY(0);
      setShouldRender(true);
      setTimeout(() => setIsAnimating(true), 10);
    } else {
      setIsAnimating(false);
      const timeout = setTimeout(() => setShouldRender(false), 300);
      return () => clearTimeout(timeout);
    }
  }, [ isOpen, activeDrawer, name ]);

  // Handle the start of drag gesture (both touch and mouse events)
  const handleDragStart = (e: React.TouchEvent | React.MouseEvent) => {
    const clientY = "touches" in e ? e.touches[0].clientY : (e as React.MouseEvent).clientY;
    setDragStartY(clientY);
  };

  // Handle the drag movement - updates sheet position as user drags
  const handleDrag = (e: React.TouchEvent | React.MouseEvent) => {
    if (dragStartY === null) return;

    const clientY = "touches" in e ? e.touches[0].clientY : (e as React.MouseEvent).clientY;
    const delta = clientY - dragStartY;

    // Only allow dragging downwards
    if (delta > 0) {
      setCurrentTranslateY(delta);
    }
  };

  // Handle the end of drag gesture
  const handleDragEnd = () => {
    if (dragStartY === null) return;

    // Calculate threshold for closing (30% of sheet height)
    const bottomSheetHeight = bottomSheetRef.current?.clientHeight || 0;
    const closeThreshold = bottomSheetHeight * 0.3;

    // If dragged past threshold, close the sheet
    if (currentTranslateY > closeThreshold) {
      setCurrentTranslateY(bottomSheetHeight);
      closeDrawer();
      onClose?.();
    } else {
      // Otherwise snap back to original position
      setCurrentTranslateY(0);
    }
    setDragStartY(null);
  };

  // Handle mouse events for desktop drag functionality
  useEffect(() => {
    if (dragStartY !== null) {
      const handleMouseMove = (e: MouseEvent) => {
        e.preventDefault();
        const delta = e.clientY - dragStartY;
        // Only allow dragging downwards
        if (delta > 0) {
          setCurrentTranslateY(delta);
        }
      };

      const handleMouseUp = () => {
        handleDragEnd();
      };

      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);

      return () => {
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);
      };
    }
  }, [ dragStartY, handleDragEnd, currentTranslateY, closeDrawer, onClose ]);

  // Add cleanup on unmount
  useEffect(() => {
    return () => {
      if (isOpen) {
        closeDrawer();
        onClose?.();
      }
    };
  }, [ isOpen, closeDrawer, onClose ]);

  if (!shouldRender) return null;

  return ReactDOM.createPortal(
    <div
      ref={bottomSheetRef}
      className={`fixed left-0 right-0 z-[11] bg-bottom-sheet-gradient rounded-t-lg shadow-lg 
        transition-all duration-300 ease-out transform
        ${isAnimating ? "translate-y-0" : "translate-y-full"}`}
      style={{
        willChange: "transform",
        height: viewportHeight,
        transform: `translateY(${currentTranslateY}px)`,
        transition: dragStartY !== null ? "none" : "transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
        position: "fixed",
        bottom: `${keyboardHeight}px`,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <div
        className={`
          drag-handle
          px-4 flex flex-col items-center
          ${!!title ? "pt-4 pb-5" : "py-4"}
        `}
        style={{
          touchAction: "none",
          userSelect: "none",
          WebkitUserSelect: "none",
          cursor: "grab"
        }}
        onMouseDown={handleDragStart}
        onTouchStart={handleDragStart}
        onTouchMove={handleDrag}
        onTouchEnd={handleDragEnd}
      >
        <div className="w-10 h-1 bg-gray-300 rounded-full"/>
        {title && <h2 className="text-lg text-[#AFAFDE] mt-4">{title}</h2>}
      </div>

      <div
        className={`relative p-4 ${!noscrollable ? "overflow-y-auto" : ""}`}
        style={{
          height: `calc(${viewportHeight} - ${title ? "116px" : "76px"})`
        }}
      >
        {children}
      </div>
    </div>,
    document.body
  );
};
