import React, { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getZodiac, getZodiacPeriod, Zodiac } from "app/types/zodiac";
import { useNatalChartAnalyseListQuery, useNatalChartProfileFetchQuery } from "app/services/natalchart";
import { useProfileFetchQuery } from "app/services/profile";
import NatalChart from "widgets/components/natalchart";
import { NatalChartAspect, NatalChartPlanet } from "app/types/natal_chart";
import { AspectType, House, UniverseEntity } from "app/types/astrology";
import Card from "widgets/components/card";
import { useTranslation } from "react-i18next";
import { useTelegram } from "context/telegram.provider";

const NatalChartInfoPage = () => {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setBackButtonVisible, setMainButtonVisible, setBackButtonOnClick } = useTelegram();
  const { profile_id } = useParams();

  const {
    data: natalChartInfo,
    isLoading: isNatalChartLoading,
  } = useNatalChartProfileFetchQuery(profile_id!);

  const {
    data: natalChartAnalysesList,
  } = useNatalChartAnalyseListQuery();

  const {
    data: profile,
    isLoading: isProfileLoading,
  } = useProfileFetchQuery(profile_id!);

  // Configure navigation routing
  useEffect(() => {
    setMainButtonVisible(false);
    setBackButtonVisible(true);
    setBackButtonOnClick(() => navigate(-1));
  }, []);

  const grouped = useMemo(() =>
    groupNatalChartData(natalChartInfo?.planets || [], natalChartInfo?.aspects || []),
  [ natalChartInfo ]
  );

  if (isProfileLoading || isNatalChartLoading) return <div/>;
  if (!profile_id || !profile || !natalChartInfo) {
    navigate(-1);
    return null;
  }

  if (profile.status !== "ready") {
    return (
      <div className="flex flex-col justify-center items-center h-full w-full">
        <div className="mb-5">
          <img src="/logo.svg" className="w-48 h-48" alt=""/>
        </div>
        <p>Мы собираем сведения</p>
        <p>нужно немного подождать ;)</p>
      </div>
    );
  }

  const period = getZodiacPeriod(profile.zodiac);

  return (
    <div>

      <div className="w-full constellation mb-10">
        <div className="mb-5 flex flex-col items-center justify-center mt-8">
          <img className="w-12 h-12 mx-auto"
            src={`/ico/constellation/Constellation=${getZodiac(profile.zodiac)}, Color=white.svg`} alt=""/>
          <h1 className="text-3xl font-bold py-4 text-center text-gold-gradient capitalize">
            {profile.first_name!}
          </h1>
          <div className="text-center">
            <p className="text-tg-theme-subtitle">
              {profile.zodiac.charAt(0).toUpperCase() + profile.zodiac.slice(1).toLowerCase()}
            </p>
            <p className="text-tg-theme-subtitle">
              {`${period.start.day.toString().padStart(2, "0")}.${period.start.month.toString().padStart(2, "0")} 
              - ${period.end.day.toString().padStart(2, "0")}.${period.end.month.toString().padStart(2, "0")}`}
            </p>
          </div>
        </div>

        <div className="mx-5">
          <NatalChart
            ascendant={110}
            planets={natalChartInfo.planets}
            houses={natalChartInfo.houses}
            aspects={natalChartInfo.aspects}
            zodiacSign={profile?.zodiac}
            showHouseNumbers={true}
            showDegrees={true}
          />
        </div>
      </div>

      <div className="flex flex-col gap-12">
        <div className="mx-16">
          <div className="grid text-center">
            <div className="grid gap-3">
              <div className="mb-2 text-[#AFAFDE]">
                {t("features.natalchart.info.analiseTitle")}
              </div>

              {natalChartAnalysesList?.map((c, index) => (
                <Card key={index}
                  onClick={() => navigate(`/profile/${profile_id}/natal_chart/analyse/${c.category}`)}>
                  <div className="flex items-center justify-between">
                    <div className="text-gold-gradient">
                      {t(`features.natalchart.info.analysesList.analyse-${c.category}`)}
                    </div>
                    <div>
                      <img src={"/ico/arrow-right.svg"} alt=""/>
                    </div>
                  </div>
                </Card>
              ))}
            </div>
          </div>
        </div>

        <div className="mx-10">
          <div className="space-y-10">
            {grouped?.map((item, index) => (
              <PlanetaryAnalysis key={index} item={item}/>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const PlanetaryAnalysis: React.FC<{item: Grouped}> = ({ item }) => (
  <div className="flex gap-2">
    <div className="mt-[0.5rem]">
      <img
        className="w-8"
        src={`/ico/planets/Rulership=${item.planet1?.toLowerCase()}.svg`}
        alt=""
      />
    </div>
    <div>
      <div className="mb-5">
        <div className="text-xs text-gold-gradient">{item.planet1}</div>
        <div className="text-xs text-gold-gradient">
          {item.zodiac}, {formatPosition(item.position!)}
        </div>
        <div className="text-xs text-gold-gradient">
          {item.house?.replace(/_/g, " ")}
        </div>
      </div>
      <div className="space-y-5">
        <div className="border-l-2 border-green-500">
          <p className="text-xs ps-2">{item.positive_effect}</p>
        </div>
        <div className="border-l-2 border-red-500">
          <p className="text-xs ps-2">{item.negative_effect}</p>
        </div>
      </div>
    </div>
  </div>
);

interface Grouped {
  type?: AspectType;
  planet1?: UniverseEntity;
  planet2?: UniverseEntity;
  zodiac?: Zodiac;
  house?: House;
  position?: number;
  positive_effect?: string;
  negative_effect?: string;
}

const groupNatalChartData = (
  planets: NatalChartPlanet[],
  aspects: NatalChartAspect[]
): Grouped[] => {
  return aspects.map(aspect => {
    const matchingPlanet = planets.find(p => p.planet === aspect.planet1);
    return {
      ...aspect,
      zodiac: matchingPlanet?.zodiac,
      house: matchingPlanet?.house,
      position: matchingPlanet?.position
    };
  });
};

const formatPosition = (position: number): string => {
  const degrees = Math.floor(position);
  const minutesDecimal = (position - degrees) * 60;
  const minutes = Math.floor(minutesDecimal);
  const seconds = Math.round((minutesDecimal - minutes) * 60);
  return `${degrees}°${minutes}'${seconds}"`;
};

export default NatalChartInfoPage