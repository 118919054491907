import React, { ReactNode } from "react";
import { HomePage, NeuroAstrologyPage } from "./pages";
import FeatureWelcome from "./features/welcome/routes";
import { AccountSettingsPage } from "./features/account";
import { ProfileCreatePage, ProfileInfoPage } from "./features/profile";
import { ZodiacInfoPage } from "./features/zodiac";
import {
  CompatibilityProfileInfoPage,
  CompatibilityProfilePage,
  CompatibilityZodiacPage
} from "./features/compatibility";
import { NatalChartAnalysePage, NatalChartInfoPage, NatalChartCreatePage } from "./features/natalchart";

export type RouteGroup = {
  layout: string;
  pages: Route[];
};

export type Route = {
  path: string;
  element: ReactNode;
  bottomBar?: boolean;
};

export const routes: RouteGroup[] = [
  {
    layout: "protected",
    pages: [
      {
        path: "/",
        element: <HomePage/>,
        bottomBar: true,
      },
      {
        path: "/settings",
        element: <AccountSettingsPage/>,
        bottomBar: true,
      },
      {
        path: "/zodiac/:zodiac",
        element: <ZodiacInfoPage/>,
        bottomBar: true,
      },
      {
        path: "/profile",
        element: <ProfileCreatePage/>,
      },
      {
        path: "/profile/:profile_id",
        element: <ProfileInfoPage/>,
        bottomBar: true,
      },
      {
        path: "/profile/:profile_id/natal_chart",
        element: <NatalChartInfoPage/>,
        bottomBar: true,
      },
      {
        path: "/profile/:profile_id/natal_chart/analyse/:type",
        element: <NatalChartAnalysePage/>,
        bottomBar: true,
      },
      {
        path: "/natal_chart",
        element: <NatalChartCreatePage/>,
        bottomBar: true,
      },
      {
        path: "/astro",
        element: <NeuroAstrologyPage/>,
        bottomBar: true,
      },
      {
        path: "/compatibility/:compatibility_id",
        element: <CompatibilityProfileInfoPage/>,
        bottomBar: true,
      },
      {
        path: "/compatibility/zodiac",
        element: <CompatibilityZodiacPage/>,
        bottomBar: true,
      },
      {
        path: "/compatibility/profile",
        element: <CompatibilityProfilePage/>,
        bottomBar: true,
      },
      {
        path: "/welcome/*",
        element: <FeatureWelcome/>,
      },
    ],
  },
];

export default routes;
