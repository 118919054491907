import React from "react";
import MoonPhaseInfo from "widgets/components/moonphase";
import PlanetInfo from "widgets/components/planetinfo";
import { LunarGrowthStage } from "../../../app/types/astrology";

type GrowthStageProps = {
  title: string;
  data: LunarGrowthStage
};

const GrowthStage: React.FC<GrowthStageProps> = ({ title, data }) => {
  return (
    <div className="grid grid-cols-3 items-center w-full p-4">
      <div className="flex justify-start">
        <span className="font-bold">{title}</span>
      </div>

      <div className="flex justify-center">
        <MoonPhaseInfo size={48} phase={data?.phase}/>
      </div>

      <div className="flex justify-end">
        <PlanetInfo position={data?.position}/>
      </div>
    </div>
  );
};

export default GrowthStage;
