import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getZodiac, getZodiacPeriod } from "app/types/zodiac";
import { useNatalChartAnalyseQuery, useNatalChartProfileFetchQuery } from "app/services/natalchart";
import { useDailyPersonalFetchQuery } from "app/services/astrology";
import { formatPosition, handleError } from "utils";
import { Drawer, useDrawer } from "../../context/drawer.context";
import { PaymentForm } from "widgets/forms/payment/payment.form";
import { usePaymentCreateMutation } from "app/services/payment";
import { PaymentOption, PaymentStatus, PaymentType } from "app/types/payment";
import { useWebApp } from "app/telegram";
import { useTranslation } from "react-i18next";

const ANALYSIS_BENEFITS = [
  "Корни вашей личности",
  "Ваши особенности в отношениях",
  "Что мешает гармонии в отношениях",
  "Ваши истинные потребности",
  "Ваше предназначение"
];

const ANALYSIS_DETAILS = [
  {
    sign: "o Дева ☽ Рак ↑ Скорпион",
    text: "Это определенно помогло мне с некоторыми ориентиами и взглядами на то, как я подхожу своим отношениям..."
  },
  {
    sign: "o Близнецы ☽ Лев ↑ Телец",
    text: "Очень верно и знакомо. Важно помнить о том, какова основная цель моей жизни, " +
      "кем я был в детстве, где находятся трещины, что я игнорировал."
  },
  {
    sign: "o Овен ☽ Водолей ↑ Лев",
    text: "Это прекрасно и очень точно про меня... сейчас я понимаю почему так, чем когда я первый раз прочитай " +
      "свой анализ. возможно, я просто забыл или не понял его тогда, но теперь всё стало ясно."
  }
];

const NatalChartAnalysePage = () => {

  const { t } = useTranslation()

  const { profile_id, type } = useParams();
  const navigate = useNavigate()
  const { openDrawer, closeDrawer } = useDrawer();
  const webApp = useWebApp()

  const {
    data: natalChartInfo,
    isLoading: isNatalChartLoading,
  } = useNatalChartProfileFetchQuery(profile_id!);

  const {
    data: natalChartAnalyse,
    isLoading: isNatalChartAnalyseLoading,
    refetch
  } = useNatalChartAnalyseQuery({ profile_id: profile_id!, analyze: `natal_chart_${type!}_analysis` });

  const {
    data: profileInfo,
    isLoading: isProfileLoading,
  } = useDailyPersonalFetchQuery({ profile_id: profile_id! });

  const [ createPaymentLink, { isError: isCreatePaymentError } ] = usePaymentCreateMutation();

  useEffect(() => {
    if (!!natalChartAnalyse && natalChartAnalyse?.status !== "created" && natalChartAnalyse?.status !== "pending") return;

    let intervalId: NodeJS.Timeout;

    if (natalChartAnalyse && natalChartAnalyse.status !== "ready") {
      intervalId = setInterval(() => {
        refetch();
      }, 5000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [ natalChartAnalyse?.status ]);

  const handlePayment = async (provider: string, paymentOptionId: string) => {
    try {
      const paymentData = await createPaymentLink({
        option_id: paymentOptionId,
        entity_id: profile_id!,
      }).unwrap();
      if (!isCreatePaymentError) {
        if (provider === "telegram") {
          webApp.openInvoice(paymentData.link, (event: string) => {
            webApp.openInvoice(paymentData.link, (event: string) => {
              if (event === PaymentStatus.Paid)
                closeDrawer();
            });
          });
        } else {
          paymentData.ok && refetch()
          closeDrawer()
        }
      }
    } catch (err) {
      if (err instanceof Error) {
        handleError(err, err.message);
      } else {
        handleError(err, "An unexpected error occurred");
      }
    }
  }

  if (isProfileLoading || isNatalChartLoading || isNatalChartAnalyseLoading) return <div/>;
  if (!profile_id || !profileInfo || !natalChartInfo) {
    navigate(-1)
    return;
  }

  const period = getZodiacPeriod(profileInfo.zodiac);

  const isAnalysisReady = !!natalChartAnalyse && natalChartAnalyse?.status === "ready";
  const isAnalysisProcessing = !!natalChartAnalyse && (natalChartAnalyse?.status === "created" || natalChartAnalyse?.status === "pending");
  const showPaymentButton = !natalChartAnalyse || (!isAnalysisReady && !isAnalysisProcessing);

  const handlePaymentSubmit = (option: PaymentOption) => {
    console.log("Payment option:", option);
    handlePayment(option?.provider, option.id)
  };

  const getPaymentType = (t: string) => {
    switch (t) {
      case "love":
        return PaymentType.NatalChartLove
      case "work":
        return PaymentType.NatalChartWork
      default:
        return "" as any
    }
  }

  return (
    <>
      <div className="flex flex-col min-h-screen px-5 constellation">

        <div className="mb-5 flex flex-col items-center justify-center mt-8">
          <img className="w-12 h-12 mx-auto"
            src={`/ico/constellation/Constellation=${getZodiac(profileInfo.zodiac)}, Color=white.svg`}
            alt=""/>
          <h1 className="text-3xl font-bold py-4 text-center text-gold-gradient capitalize">
            {profileInfo.first_name!}
          </h1>
          <div className="text-center">
            <p className="text-tg-theme-subtitle">
              {profileInfo.zodiac.charAt(0).toUpperCase() + profileInfo.zodiac.slice(1).toLowerCase()}
            </p>
            <p className="text-tg-theme-subtitle">
              {`${period?.start?.day?.toString().padStart(2, "0")}.${period?.start?.month?.toString().padStart(2, "0")}
            - 
            ${period?.end?.day?.toString().padStart(2, "0")}.${period?.end?.month?.toString().padStart(2, "0")}`}
            </p>
          </div>
        </div>

        <div className="mb-5 text-center text-gold-gradient px-8">
          {t(`features.natalchart.analyse.${type?.toLowerCase()}.title`)}
        </div>

        <div className="relative w-full max-w-sm mx-auto my-5">
          <div className="flex items-center justify-between">
            <div className="w-40 h-40 mx-auto">
              <img className="w-full h-full" src={`/img/analyse/${type}.svg`} alt=""/>
            </div>
          </div>
        </div>
      </div>

      <div>
        {isAnalysisProcessing && (
          <div className="flex flex-col items-center justify-center flex-1 px-5">
            <p className="text-center text-tg-theme-subtitle">
              Подготавливаем для вас анализ...
            </p>
          </div>
        )}

        {isAnalysisReady && (
          <div className="px-8 my-10 space-y-10">
            {natalChartAnalyse.details?.map((detail, index) => (
              <AnalysisDetailCard key={index} detail={detail}/>
            ))}
          </div>
        )}

        {showPaymentButton && (
          <div className="px-8">
            <div>
              Чтобы построить крепкие и гармоничные отношения, важно глубоко понять себя и свои особенности.
              Наша нейросеть проведет детальный анализ вашей натальной карты, предлагая объективный и точный взгляд на
              все аспекты вашей личности, связанные с отношениями и любовью.
            </div>

            <div className="flex flex-col items-center justify-center w-full my-10">
              <div className="text-center mb-5">В данном анализе будет:</div>
              <AnalysisBenefits/>
            </div>

            <div className="my-10">
              Открыйте новый уровень осознанности в отношениях и получите точные ответы на самые важные вопросы о любви
              и совместимости
            </div>

            <div
              className="flex justify-center items-center rounded-xl p-4 bg-card-gradient mb-5 cursor-pointer"
              onClick={() => openDrawer("pay")}>

              <div className="mb-1 flex justify-center">
                <img src="/ico/arrow-up.svg" alt=""/>
              </div>
              <div className="px-5 font-bold text-base text-gold-gradient">
                Заказать анализ
              </div>
              <div className="mb-1 flex justify-center">
                <img src="/ico/arrow-up.svg" alt=""/>
              </div>
            </div>

            <div className="w-full flex justify-center my-10">
              <hr className="w-3/5"/>
            </div>

            <div className="grid gap-12">
              {ANALYSIS_DETAILS.map((testimonial, index) => (
                <DetailCard
                  key={index}
                  sign={testimonial.sign}
                  text={testimonial.text}
                />
              ))}
            </div>

          </div>
        )}

        <Drawer name="pay" maxHeight="50vh">
          <PaymentForm
            type={getPaymentType(type!)}
            onSubmit={handlePaymentSubmit}
            buttonText="Заказать анализ"
            description="Закажите расширенный анализ с расшифровкой которая будет всегда вам доступна"
          />
        </Drawer>

      </div>
    </>
  )
}


type AnalysisDetail = {
  planet: string;
  zodiac: string;
  position: number;
  house: string;
  summary: string;
  description: string;
};

const DetailCard: React.FC<{sign: string, text: string}> = ({ sign, text }) => (
  <div className="grid gap-3">
    <p className="italic text-[#AFAFDE]">{sign}</p>
    <p className="italic text-[#AFAFDE]">{text}</p>
  </div>
);

const AnalysisDetailCard: React.FC<{detail: AnalysisDetail}> = ({ detail }) => (
  <div className="flex gap-2">
    <div className="mt-[0.475rem]">
      <img
        className="w-8"
        style={{ minWidth: "2rem" }}
        src={`/ico/planets/Rulership=${detail.planet?.toLowerCase()}.svg`}
        alt=""
      />
    </div>
    <div>
      <div className="mb-5 text-xs">
        <div className="font-bold text-[#AFAFDE]">{detail.planet}</div>
        <div className="font-bold text-[#AFAFDE]">
          {detail.zodiac}, {formatPosition(detail.position!)}
        </div>
        <div className="font-bold text-[#AFAFDE]">{detail.house?.replace(/_/g, " ")}</div>
      </div>
      <div className="text-gold-gradient mb-5">{detail.summary}</div>
      <div className="text-[#AFAFDE]">{detail.description}</div>
    </div>
  </div>
);

const AnalysisBenefits: React.FC = () => (
  <div className="inline-block text-left">
    {ANALYSIS_BENEFITS.map((benefit, index) => (
      <div key={index} className="flex items-center gap-4 py-2">
        <img src="/ico/arrow-up.svg" alt=""/>
        <p>{benefit}</p>
      </div>
    ))}
  </div>
);

export default NatalChartAnalysePage