import React, { useEffect, useRef, useState } from "react";
import { PlanetInfluence } from "app/types/astrology";
import { getZodiac } from "app/types/zodiac";

type PlanetInfluenceProps = {
  data: PlanetInfluence;
};

const SunInfluenceComponent: React.FC<PlanetInfluenceProps> = ({ data }) => {
  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col items-center space-y-4 p-6">
        <div className="relative w-full h-36">

          <ArcBackground/>

          <div className="absolute -top-2 left-1/2 transform -translate-x-1/2 w-full max-w-[300px]">
            <div className="mb-5 flex justify-center">
              <img src="/ico/arrow-up.svg" alt=""/>
            </div>
            <div className="relative flex items-center justify-center">
              <div className="absolute left-1/2 transform -translate-x-1/2">
                <img src={"/ico/planets/Rulership=sun.svg"} alt="" className="w-[3rem] h-[3rem]"/>
              </div>

              <div className="absolute" style={{ left: "calc(50% + 3rem)" }}>
                <div className="flex gap-2">
                  <div className="flex items-center gap-1">
                    <img src="/ico/angle.svg" alt=""/>
                    <span className="">{data?.position}°</span>
                  </div>

                  {/* <div className="flex items-center gap-1">
                    <img src="/ico/star-amount.svg" alt=""/>
                    <span className=""></span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="absolute" style={{ left: 0, bottom: -25, transform: "translate(-50%, 0)", zIndex: 0 }}>
            <img
              alt="Left Icon" className="w-[3rem] h-[3rem]"
              src={`/ico/constellation/Constellation=${getZodiac(data?.current_zodiac)}, Color=white.svg`}/>
          </div>

          <div className="absolute" style={{ right: 0, bottom: -25, transform: "translate(50%, 0)", zIndex: 0 }}>
            <img
              alt="Right Icon" className="w-[3rem] h-[3rem]"
              src={`/ico/constellation/Constellation=${getZodiac(data?.next_zodiac)}, Color=white.svg`}
            />
          </div>

          <p className="absolute left-1/2 -translate-x-1/2 translate-y-36 text-center w-[60%] max-w-[60%]">
            {data?.influence}
          </p>
        </div>

      </div>
    </div>
  );
};

type ArcBackgroundProps = {
  arcColor?: string;
};

const ArcBackground: React.FC<ArcBackgroundProps> = ({
  arcColor = "#FCF0A1",
}) => {
  const [ arcPath, setArcPath ] = useState<string>("");
  const svgRef = useRef<SVGSVGElement>(null);

  const updateArcPath = () => {
    if (svgRef.current) {
      const width = svgRef.current.clientWidth;
      const height = svgRef.current.clientHeight;

      const newPath = `M 0 ${height} Q ${width / 2} 0, ${width} ${height}`;
      setArcPath(newPath);
    }
  };

  useEffect(() => {
    updateArcPath();
    window.addEventListener("resize", updateArcPath);

    return () => {
      window.removeEventListener("resize", updateArcPath);
    };
  }, []);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef} className="absolute bottom-0 left-0"
      width="100%" height="100%"
    >
      <path d={arcPath} stroke={arcColor} strokeWidth={2} fill="none"/>
    </svg>
  );
};

export default SunInfluenceComponent;
