import React, { FC, useEffect, useState } from "react";
import DropDown from "widgets/forms/dropdown";
import { Profile } from "app/types/profile";
import { Zodiac } from "app/types/zodiac";
import { useTranslation } from "react-i18next";
import ProfileForm from "features/profile/profile.form";

export type AstroProfile = {
  id?: string;
  name: string;
  birthday: number;
  country: string;
  city: string;
  street: string;
  latitude: number;
  longitude: number;
  zodiac: Zodiac;
}

export interface AstroProfileFormProps {
  profiles: Profile[]
  value?: AstroProfile
  onSelect: (p: AstroProfile) => void
}

const defaultData = {
  name: "",
  location: "",
  birthday: Date.UTC(new Date().getFullYear() - 20, 0, 1),
  country: "",
  city: "",
  street: "",
  latitude: 0,
  longitude: 0,
  zodiac: Zodiac.ARIES
} as AstroProfile

const AstroProfileForm: FC<AstroProfileFormProps> = ({ profiles = [], value, onSelect }) => {
  const { t } = useTranslation();
  const [ selectedOption, setSelectedOption ] = useState<string | undefined>(value?.id || profiles[0]?.id);
  const [ profileData, setProfileData ] = useState<Profile>({
    id: "",
    first_name: "",
    last_name: "",
    birthday: defaultData.birthday,
    zodiac: Zodiac.ARIES,
    country: "",
    city: "",
    street: "",
    latitude: 0,
    longitude: 0,
    status: "",
    extended: false,
    extended_expiration: 0,
    is_default: false,
    created: 0,
    updated: 0
  });

  useEffect(() => {
    if (value && !value.id) {
      setProfileData({
        ...profileData,
        first_name: value.name.split(" ")[0] || "",
        last_name: value.name.split(" ")[1] || "",
        birthday: value.birthday,
        country: value.country,
        city: value.city,
        street: value.street,
        latitude: value.latitude,
        longitude: value.longitude,
        zodiac: value.zodiac
      });
    }
  }, [ value ]);

  const options = profiles?.map(p => ({
    value: p.id,
    label: `${p.first_name || ""} ${p.last_name || ""}`.trim()
  }));

  const handleProfileChange = (selected: {value: string, label: string}) => {
    setSelectedOption(selected.value);
  };

  const renderTitle = (selected: {value: string, label: string} | undefined) => (
    <>
      {selected ? (
        <span className="flex items-center gap-2">
          <img className="w-6 h-6 mx-auto" src={"/img/constellation/gemini.svg"} alt=""/>
          {selected.label}
        </span>
      ) : t("widgets.forms.astroProfile.selectSection.dropdown.defaultTitle")}
    </>
  );

  const renderCustomOption = (option: {value: string, label: string}) => (
    <span className="flex items-center gap-2">
      {option.label}
    </span>
  );

  const selectProfile = () => {
    const p = profiles?.find(p => p.id === selectedOption);
    if (!p) return;
    const data = {
      id: p.id,
      name: `${p.first_name || ""} ${p.last_name || ""}`.trim(),
      location: `${p.city || ""} ${p.country || ""}`.trim(),
      birthday: p.birthday,
      country: p.country,
      city: p.city,
      street: p.street,
      latitude: p.latitude,
      longitude: p.longitude,
      zodiac: p.zodiac
    } as AstroProfile;
    onSelect(data);
  };

  const handleProfileFormChange = (data: Partial<Profile>) => {
    setProfileData(prevData => ({ ...prevData, ...data }));
  };

  const isValid = () => {
    return profileData.first_name &&
      profileData.last_name &&
      profileData.birthday;
  };

  return (
    <div>
      <div className="mb-6">
        <p className="text-center mb-4">
          {t("widgets.forms.astroProfile.selectSection.title")}
        </p>

        <div className="mb-5">
          <DropDown
            options={options}
            value={value?.id || selectedOption}
            onChange={handleProfileChange}
            renderOption={renderCustomOption}
            renderTitle={renderTitle}
          />
        </div>

        <div className="w-full flex justify-center">
          <button
            className="px-6 py-2 rounded-xl bg-card-gradient text-tg-theme-section-header"
            onClick={selectProfile}
          >
            {t("common.button.select")}
          </button>
        </div>
      </div>

      <div className="w-full flex justify-center my-10">
        <hr className="w-3/5"/>
      </div>

      <div className="pb-10">
        <p className="text-center mb-4">
          {t("widgets.forms.astroProfile.fillSection.title")}
        </p>

        <ProfileForm
          value={profileData}
          onChange={handleProfileFormChange}
        />

        <div className="w-full flex justify-center mt-5">
          <button
            className={`px-6 py-2 rounded-xl bg-card-gradient ${isValid() ? "text-tg-theme-section-header" : "opacity-30"}`}
            disabled={!isValid()}
            onClick={() => {
              if (isValid()) {
                const astroData: AstroProfile = {
                  name: `${profileData.first_name} ${profileData.last_name}`,
                  birthday: profileData.birthday,
                  country: profileData.country,
                  city: profileData.city,
                  street: profileData.street,
                  latitude: profileData.latitude,
                  longitude: profileData.longitude,
                  zodiac: profileData.zodiac
                };
                onSelect(astroData);
              }
            }}
          >
            {t("common.button.select")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AstroProfileForm;