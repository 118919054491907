import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTelegram } from "../context/telegram.provider";

interface SectionProps {
  title: string;
  description: string;
  children: React.ReactNode;
  buttonText: string;
  onButtonClick: () => void;
}


export const NeuroAstrologyPage = () => {
  const navigate = useNavigate();
  const { setBackButtonVisible, setMainButtonVisible, setBackButtonOnClick } = useTelegram();

  useEffect(() => {
    setMainButtonVisible(false);
    setBackButtonVisible(true);
    setBackButtonOnClick(() => navigate("/"));
  }, []);

  return (
    <div className="w-full">
      <div className="flex flex-col items-center justify-center mt-8">
        <img className="w-12 h-12" src="/ico/zodicus.svg" alt="Zodicus"/>
      </div>

      <Section
        title="Natal Chart"
        description="Check person natal chart and analyze it"
        buttonText="Check person natal chart"
        onButtonClick={() => navigate("/natal_chart")}
      >
        <CardBox image="/ico/people.svg" label="Person"/>
      </Section>

      <Divider/>

      <Section
        title="Zodiac Compatibility"
        description="Check compatibility between zodiac signs"
        buttonText="Check zodiac signs compatibility"
        onButtonClick={() => navigate("/compatibility/zodiac")}
      >
        <div className="flex items-center my-5">
          <CardBox image="/img/constellation/taurus.svg" label="Taurus"/>
          <div className="text-2xl mx-4">x</div>
          <CardBox image="/img/constellation/leo.svg" label="Leo"/>
        </div>
      </Section>

      <Divider/>

      <Section
        title="Personal Compatibility"
        description="Check personal compatibility between astro-profiles by natal chart, naming, and numerical data"
        buttonText="Check extended profile compatibility"
        onButtonClick={() => navigate("/compatibility/profile")}
      >
        <div className="flex items-center my-5">
          <CardBox image="/ico/people.svg" label="Person 1"/>
          <div className="text-2xl mx-4 font-bold text-gold-gradient">x</div>
          <CardBox image="/ico/people.svg" label="Person 2"/>
        </div>
      </Section>
    </div>
  );
};

const Section = ({ title, description, children, buttonText, onButtonClick }: SectionProps) => (
  <div className="flex flex-col items-center my-10 px-10 constellation !min-h-72">
    <div className="mb-5 text-center">
      <h2 className="text-2xl text-gold-gradient">{title}</h2>
      <p className="mt-2 text-gray-500">{description}</p>
    </div>

    {children}

    <button
      onClick={onButtonClick}
      className="flex justify-between items-center rounded-xl mt-5 px-5 py-3 mb-5 bg-card-gradient cursor-pointer w-full min-w-[22rem]"
    >
      <div className="text-gold-gradient text-center w-full">
        {buttonText}
      </div>
      <div className="flex justify-end">
        <img src="/ico/arrow-right.svg" alt=""/>
      </div>
    </button>
  </div>
);

const CardBox = ({ image, label, className = "" }: {image: string; label: string; className?: string}) => (
  <div className={`
    w-[8rem] h-[8rem] text-center p-3
    flex flex-col items-center justify-between bg-card-stars border rounded-2xl 
    ${className}
  `}
  >
    <div className="w-24 h-24 flex items-center justify-center bg-custom-radial">
      <img
        src={image}
        alt=""
        className={image.includes("people") ? "w-10 h-10" : "w-16 h-16"}
        loading="lazy"
      />
    </div>
    <p className="mt-2 text-[#AFAFDE]">{label}</p>
  </div>
);

const Divider = () => (
  <div className="mb-1 flex justify-center">
    <img src="/ico/double-arrow-down.svg" alt=""/>
  </div>
);