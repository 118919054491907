import { api } from "./api"
import { Payment, PaymentOption, PaymentType } from "../types/payment";

function getPaymentOptionFromResponse(src: PaymentOptionResponse): PaymentOption {
  return { ...src }
}

function getPaymentFromResponse(src: PaymentResponse): Payment {
  return { ...src }
}

export type PaymentOptionListRequest = {
  type: PaymentType;
  provider?: string;
}

export type PaymentRequest = {
  option_id: string;
  entity_id: string;
}

export type PaymentResponse = {
  ok: boolean
  link?: string
}

export type PaymentOptionResponse = {
  id: string;
  provider: string;
  name: string;
  currency: string;
  cost: number;
}

export type PaymentOptionListResponse = {
  items: PaymentOptionResponse[]
}

export const paymentApi = api.injectEndpoints({
  endpoints: (build) => ({

    paymentOptionsList: build.query<PaymentOption[], PaymentOptionListRequest>({
      query: (params) => ({
        url: "v1/payment/options",
        method: "GET",
        params: params
      }),
      transformResponse: (response: PaymentOptionListResponse): PaymentOption[] => {
        return response.items.map(getPaymentOptionFromResponse);
      },
    }),

    paymentCreate: build.mutation<Payment, PaymentRequest>({
      query: (payment: PaymentRequest) => ({
        url: "v1/payment",
        method: "POST",
        body: payment,
      }),
      transformResponse: (response: PaymentResponse): Payment => {
        return getPaymentFromResponse(response);
      },
    }),

  }),
})

export const {
  usePaymentOptionsListQuery,
  usePaymentCreateMutation,
} = paymentApi

