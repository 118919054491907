import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAccount } from "hooks/useAccount";
import { useTelegram } from "context/telegram.provider";
import { Drawer, useDrawer } from "context/drawer.context";
import { useProfileListQuery, useProfileUpdateMutation } from "app/services/profile";
import { useAccountSettingsUpdateMutation } from "app/services/account";
import { getZodiac, Zodiac } from "app/types/zodiac";
import ZodiacList from "../zodiac/zodiacList";
import ReferralLink from "widgets/components/referallink";
import Card from "widgets/components/card";
import Language from "widgets/forms/language";
import Timezone from "widgets/forms/timezone";
import ZodiacSelect from "widgets/forms/zodiacselect";
import { languages } from "widgets/forms/language/languages";
import TimePicker from "widgets/forms/timepicker";
import { formatDateTime, handleError } from "utils";
import ProfileForm from "../profile/profile.form";
import { Profile } from "app/types/profile";

type FormDataProfile = Partial<Profile>;

type FormDataSettings = {
  language: string;
  timezone: string;
  report_time: string;
  report_language: string;
  zodiacs: Zodiac[];
};

type ProfileProps = "first_name" | "last_name" | "city" | "country" | "birthday";
type SettingProps = "language" | "timezone" | "report_time" | "report_language";

const AccountSettingsPage = () => {
  const navigate = useNavigate();
  const { settings } = useAccount();
  const { screen, setBackButtonVisible, setMainButtonVisible, setBackButtonOnClick } = useTelegram();
  const { openDrawer, closeDrawer } = useDrawer();

  const [ updateAccountSettings ] = useAccountSettingsUpdateMutation();
  const [ updateProfile ] = useProfileUpdateMutation();

  const [ formProfileData, setFormProfileData ] = useState<FormDataProfile>();
  const [ formSettingsData, setFormSettingsData ] = useState<FormDataSettings>({ ...settings });

  const {
    data: profiles,
    isLoading: isProfilesLoading,
  } = useProfileListQuery();

  const [ tempReportTime, setTempReportTime ] = useState<string>("");
  const [ tempZodiacs, setTempZodiacs ] = useState<Zodiac[]>([]);
  const [ invalidFields, setInvalidFields ] = useState<string[]>([]);

  // Configure navigation routing
  useEffect(() => {
    setMainButtonVisible(false);
    setBackButtonVisible(true);
    setBackButtonOnClick(() => navigate("/"));
  }, []);

  // Configure navigation routing
  useEffect(() => {
    if (!!screen) return;
    setBackButtonVisible(true);
    setBackButtonOnClick(() => navigate("/"));
  }, [ screen ]);

  useEffect(() => {
    if (!!profiles?.length) {
      setFormProfileData(profiles?.filter(p => p.is_default)[0])
    }
  }, [ profiles ]);

  if (isProfilesLoading) return <div/>;
  if (!profiles?.length) {
    navigate("/");
    return null;
  }

  const defaultProfile = profiles?.filter(p => p.is_default)[0];
  const validateProfile = () => {
    const invalid = [];
    if (!formProfileData?.first_name) invalid.push("first_name");
    if (!formProfileData?.last_name) invalid.push("last_name");
    if (!formProfileData?.birthday) invalid.push("birthday");
    return invalid;
  };

  const handleUpdateProfile = async () => {
    const invalid = validateProfile();
    if (invalid.length > 0) {
      setInvalidFields(invalid);
      return;
    }
    setInvalidFields([]);

    try {
      const updateData = {
        id: defaultProfile?.id,
        data: {
          first_name: formProfileData?.first_name || defaultProfile.first_name,
          last_name: formProfileData?.last_name || defaultProfile.last_name,
          city: formProfileData?.city || defaultProfile.city,
          country: formProfileData?.country || defaultProfile.country,
          street: formProfileData?.street || defaultProfile.street,
          latitude: Number(formProfileData?.latitude || defaultProfile.latitude),
          longitude: Number(formProfileData?.longitude || defaultProfile.longitude),
          birthday: formProfileData?.birthday != null ? Math.floor(formProfileData.birthday / 1000) : defaultProfile.birthday,
        }
      };

      const profileResult = await updateProfile(updateData).unwrap();
      if (profileResult) {
        closeDrawer();
      }
    } catch (err) {
      if (err instanceof Error) {
        handleError(err, err.message);
      } else {
        handleError(err, "An unexpected error occurred");
      }
    }
  };

  const handleUpdateSettings = async () => {
    try {
      const newSettings = {
        ...formSettingsData,
        zodiacs: tempZodiacs.length > 0 ? tempZodiacs : formSettingsData.zodiacs
      };
      await updateAccountSettings(newSettings).unwrap();
      setFormSettingsData(newSettings);
      setTempZodiacs([]);
      closeDrawer();
    } catch (err) {
      handleError(err, err instanceof Error ? err.message : "An unexpected error occurred");
    }
  };

  const handleChangeProfile = (data: Partial<Profile>) => {
    setFormProfileData({ ...formProfileData, ...data });
  };

  const handleChangeZodiacs = (zodiacs: Zodiac[]) => {
    setTempZodiacs(zodiacs);
  }

  const handleTimeChange = (v: string) => {
    setTempReportTime(v);
  }

  const handleUpdateReportTime = async () => {
    const newSettings = { ...formSettingsData, report_time: tempReportTime };
    try {
      await updateAccountSettings(newSettings).unwrap();
      setFormSettingsData(newSettings);
      closeDrawer();
    } catch (err) {
      handleError(err, err instanceof Error ? err.message : "An unexpected error occurred");
    }
  };

  const handleChangeSettings = async (name: SettingProps | ProfileProps, value: string) => {
    const newSettings = { ...formSettingsData, [name]: value };
    setFormSettingsData(newSettings);

    try {
      await updateAccountSettings(newSettings).unwrap();
      closeDrawer();
    } catch (err) {
      handleError(err, err instanceof Error ? err.message : "An unexpected error occurred");
    }
  };


  return (
    <div className="w-full min-h-screen constellation flex flex-col">
      <div className="flex flex-col items-center justify-center mt-8">
        <h1 className="text-3xl text-tg-theme-section-header">Zodycus</h1>
      </div>

      <ProfileHeader profile={defaultProfile} onEdit={() => openDrawer("profile")}/>

      <Section title="Реферальная программа">
        <p className="text-xs font-bold text-center mb-5 text-[#AFAFDE]">
          Вы можете повысить уровень профиля за приглашенных вами друзей.
          Расскажите им о приложении и вы оба получите дополнительно по 3 дня доступа.
        </p>
        <ReferralLink/>
      </Section>

      <Section title="Настройка локализации">
        <SettingsCard
          title="Язык интерфеса"
          value={languages.find(l => l.value === formSettingsData.language)?.hint || ""}
          onClick={() => openDrawer("language")}
        />
        <SettingsCard
          title="Язык гороскопов"
          value={languages.find(l => l.value === formSettingsData.report_language)?.hint || ""}
          onClick={() => openDrawer("report_language")}
        />
        <SettingsCard
          title="Часовой пояс"
          value={formSettingsData.timezone}
          onClick={() => openDrawer("timezone")}
        />
      </Section>

      <Section title="Отслежваемые горосокопы">
        <div className="w-full flex justify-center items-center gap-8 mb-10">
          <div className="text-xs text-[#AFAFDE]">Время доставки</div>
          <div
            className="bg-card-gradient text-tg-theme-section-header rounded-xl px-5 py-1 cursor-pointer"
            onClick={() => openDrawer("report_time")}
          >
            {formSettingsData.report_time}
          </div>
        </div>

        <div className="mb-5">
          <ZodiacList
            selectedZodiacs={formSettingsData?.zodiacs}
            showOnlySelected={true}
          />
        </div>

        <div className="grid justify-center">
          <button
            className="mt-5 px-8 py-2 rounded-xl bg-card-gradient text-tg-theme-section-header"
            onClick={() => openDrawer("zodiacs")}
          >
            Изменить
          </button>
        </div>
      </Section>

      <Drawer name="profile" title="Персональная информация" noscrollable>
        <>
          <div className="mb-5">
            <ProfileForm value={formProfileData} onChange={handleChangeProfile} invalidFields={invalidFields}/>
          </div>

          <div className="w-full flex justify-center">
            <button className="mt-5 px-8 py-2 rounded-xl bg-card-gradient text-tg-theme-section-header "
              onClick={handleUpdateProfile}
            >
              Обновить
            </button>
          </div>
        </>
      </Drawer>

      <Drawer name="report_time" title="Время доставки гороскопов" maxHeight="60vh" noscrollable>
        <>
          <div className="mb-5">
            <TimePicker
              label={"Время доставки"}
              value={formSettingsData.report_time}
              onChange={handleTimeChange}
              alwaysTimeShowOpen={true}
            />
          </div>

          <div className="w-full flex justify-center">
            <button
              className="mt-5 px-8 py-2 rounded-xl bg-card-gradient text-tg-theme-section-header "
              onClick={handleUpdateReportTime}
            >
              Обновить
            </button>
          </div>
        </>
      </Drawer>

      <Drawer name="language" title="Выберите язык интерфейса" noscrollable>
        <Language value={formSettingsData.language}
          onSelect={(v) => handleChangeSettings("language", v)}/>
      </Drawer>

      <Drawer name="report_language" title="Выберите язык гороскопов" noscrollable>
        <Language value={formSettingsData.report_language}
          onSelect={(v) => handleChangeSettings("report_language", v)}/>
      </Drawer>

      <Drawer name="timezone" title="Выберите часовой пояс" noscrollable>
        <Timezone value={formSettingsData.timezone}
          onSelect={(v) => handleChangeSettings("timezone", v)}/>
      </Drawer>

      <Drawer name="zodiacs" title="Выберите знаки зодиака для отслеживания">
        <>
          <ZodiacSelect
            value={formSettingsData.zodiacs}
            onChange={handleChangeZodiacs}
          />

          <div className="w-full flex justify-center">
            <button
              className="mt-5 px-8 py-2 rounded-xl bg-card-gradient text-tg-theme-section-header "
              onClick={handleUpdateSettings}
            >
              Обновить
            </button>
          </div>
        </>
      </Drawer>

    </div>
  );
};

interface SectionProps {
  title: string;
  children: React.ReactNode;
  className?: string;
}

const Section = ({ title, children, className = "" }: SectionProps) => (
  <div className={`mb-10 px-10 ${className}`}>
    <h2 className="font-bold text-center text-[#AFAFDE] mb-5">
      {title}
    </h2>
    {children}
  </div>
);

const ProfileHeader = ({ profile, onEdit }: {profile: any; onEdit: () => void}) => (
  <div className="w-full mt-5 py-5 text-center">
    <img
      className="w-24 h-24 mx-auto mb-2"
      src={`/img/constellation/${getZodiac(profile?.zodiac).toLowerCase()}.svg`}
      alt=""
    />
    <p className="capitalize text-tg-theme-section-header">
      {`${profile?.first_name || ""} ${profile?.last_name || ""}`.trim()}
    </p>
    <p className="text-xs text-tg-theme-section-header">
      {`${[
        profile?.birthday ? formatDateTime(new Date(profile.birthday)) : null,
        profile?.city,
        profile?.country
      ].filter(Boolean).join(", ")}`.trim()}
    </p>
    <button
      className="mt-5 px-8 py-2 rounded-xl bg-card-gradient text-tg-theme-section-header"
      onClick={onEdit}
    >
      Изменить
    </button>
  </div>
);

const SettingsCard = ({ title, value, onClick }: {title: string; value: string; onClick: () => void}) => (
  <Card title={title} className="mb-3" titleClassName="normal-case" onClick={onClick}>
    <div className="flex justify-between">
      <h5 className="text-[#AFAFDE]">{value}</h5>
      <div className="inline-flex items-center justify-end">
        <img src="/ico/arrow-down.svg" alt=""/>
      </div>
    </div>
  </Card>
);

export default AccountSettingsPage