import { api } from "./api";
import {
  CompatibilityForecast,
  CompatibilityGroup,
  CompatibilityGroupInfo,
  CompatibilityNumerologyNumbers,
  CompatibilityProfile,
  CompatibilityProfileData,
  CompatibilityTrinity,
  CompatibilityZodiac,
} from "../types/compatibility";
import { Zodiac } from "../types/zodiac";

export type CompatibilityForecastResponse = {
  id: string,
  week: number,
  zodiac: Zodiac,
  compatibility: {
    mood: CompatibilityResponse;
    love: CompatibilityResponse;
    work: CompatibilityResponse;
  }
};

export type CompatibilityListFilter = {
  profile_id?: string
}

export type CompatibilityResponse = {
  id: string;
  zodiac: Zodiac;
  group: CompatibilityGroup;
  percent: number;
};

export type CompatibilityZodiacCreateRequest = {
  zodiac: Zodiac;
  partner_zodiac: Zodiac;
};

export type CompatibilityZodiacResponse = {
  zodiac: Zodiac,
  partner_zodiac: Zodiac,
  mood: CompatibilityGroupInfo;
  love: CompatibilityGroupInfo;
  money: CompatibilityGroupInfo;
  summary: string;
  efficiency: string;
};

export interface CompatibilityProfileCreateRequest {
  profile_id?: string,
  profile?: CompatibilityProfileData
  partner_profile_id?: string
  partner_profile?: CompatibilityProfileData
}

export type CompatibilityProfileResponse = {
  id: string
  status: string
  profile1: CompatibilityProfileData;
  profile2: CompatibilityProfileData;
  general: CompatibilityGroupInfo;
  mood: CompatibilityGroupInfo;
  love: CompatibilityGroupInfo;
  money: CompatibilityGroupInfo;
  percentage: number;
  natal_chart_1: string;
  natal_chart_2: string;
  summary: string;
  numerology_numbers: CompatibilityNumerologyNumbers;
  trinity: CompatibilityTrinity;
}

export type CompatibilityProfileListResponse = {
  items: CompatibilityProfileResponse[]
}

function getCompatibilityFromResponse(
  src: CompatibilityForecastResponse,
): CompatibilityForecast {
  return {
    ...src,
  };
}

function getCompatibilityZodiacFromResponse(
  src: CompatibilityZodiacResponse,
): CompatibilityZodiac {
  return {
    ...src,
  };
}

function getCompatibilityProfileFromResponse(
  src: CompatibilityProfileResponse,
): CompatibilityProfile {
  return {
    ...src,
  };
}

export const compatibilityApi = api.injectEndpoints({
  endpoints: (build) => ({
    compatibilityFetch: build.query<CompatibilityForecast, void>({
      query: () => ({
        url: "v1/compatibility",
        method: "GET",
      }),
      transformResponse: (response: CompatibilityForecastResponse): CompatibilityForecast => {
        return getCompatibilityFromResponse(response);
      },
    }),

    // CompatibilityZodiac
    compatibilityZodiacCreate: build.mutation<CompatibilityZodiac, CompatibilityZodiacCreateRequest>({
      query: (data: CompatibilityZodiacCreateRequest) => ({
        url: "v1/compatibility/zodiac",
        method: "POST",
        body: data,
      }),
      transformResponse: (response: CompatibilityZodiacResponse): CompatibilityZodiac => {
        return getCompatibilityZodiacFromResponse(response);
      },
    }),

    // CompatibilityProfile
    compatibilityProfileCreate: build.mutation<CompatibilityProfile, CompatibilityProfileCreateRequest>({
      query: (data: CompatibilityProfileCreateRequest) => ({
        url: "v1/compatibility/profile",
        method: "POST",
        body: data,
      }),
      transformResponse: (response: CompatibilityProfileResponse): CompatibilityProfile => {
        return getCompatibilityProfileFromResponse(response);
      },
      invalidatesTags: [ { type: "ProfileCompatibility", id: "LIST" } ],
    }),
    compatibilityProfileFetch: build.query<CompatibilityProfile, string>({
      query: (compatibility_id) => ({
        url: `v1/compatibility/profile/${compatibility_id}`,
        method: "GET",
      }),
      transformResponse: (response: CompatibilityProfileResponse): CompatibilityProfile => {
        return getCompatibilityProfileFromResponse(response);
      },
      providesTags: (result, error, id) => [ { type: "ProfileCompatibility", id } ],
    }),
    compatibilityProfileList: build.query<CompatibilityProfile[], CompatibilityListFilter | void>({
      query: (params) => ({
        url: "v1/compatibility/profiles",
        method: "GET",
        params: params || undefined,
      }),
      transformResponse: (response: CompatibilityProfileListResponse): CompatibilityProfile[] => {
        return response.items.map(getCompatibilityProfileFromResponse);
      },
      providesTags: [ { type: "ProfileCompatibility", id: "LIST" } ],
    }),
  }),
});

export const {
  useCompatibilityFetchQuery,
  useCompatibilityZodiacCreateMutation,
  useCompatibilityProfileCreateMutation,
  useCompatibilityProfileListQuery,
  useCompatibilityProfileFetchQuery,
} =
  compatibilityApi;

export const {
  endpoints: {
    compatibilityFetch,
    compatibilityZodiacCreate,
    compatibilityProfileCreate,
    compatibilityProfileFetch,
    compatibilityProfileList,
  },
} = compatibilityApi;
