import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useScreen } from "context/telegram.provider";
import { Drawer, useDrawer } from "../../context/drawer.context";
import { getZodiac, getZodiacPeriod } from "app/types/zodiac";
import { useDailyPersonalFetchQuery } from "app/services/astrology";
import { useNatalChartProfileFetchQuery } from "app/services/natalchart";
import { useProfileDeleteMutation, useProfileFetchQuery, useProfileUpdateMutation } from "app/services/profile";
import { usePaymentCreateMutation } from "app/services/payment";
import Card from "widgets/components/card";
import ProfileForm from "./profile.form";
import { Profile } from "app/types/profile";
import ZodiacPlanet from "widgets/components/zodiacplanet/zodiacPlanet";
import RecommendationInfo from "widgets/components/recommendation";
import HappyNumber from "widgets/components/happynumber/happynumber";
import NatalChart from "widgets/components/natalchart/natalChart";
import ProfileCompatibilityList from "./profile_compatibility";
import EfficiencyCalendarFeature from "../efficiency/efficiency_calendar";
import CompatibilityHistoryList from "../compatibility/compatibilityHistoryList";
import ProfileSubscriptionForm from "widgets/forms/profile_subscription";
import { PaymentOptionType, PaymentStatus } from "app/types/payment";
import { useWebApp } from "app/telegram";
import { handleError } from "utils";
import moment from "moment";

const ProfileInfoPage = () => {

  const { profile_id, date } = useParams();

  const navigate = useNavigate();
  const webApp = useWebApp()

  const { setMainButtonVisible, setBackButtonVisible, setBackButtonOnClick } = useScreen();
  const { openDrawer, closeDrawer } = useDrawer();

  const [ formProfileData, setFormProfileData ] = useState({
    birthday: Date.UTC(new Date().getFullYear() - 20, 0, 1)
  } as Profile);
  const [ invalidFields, setInvalidFields ] = useState<string[]>([]);

  const { data: profile, isLoading, refetch } = useProfileFetchQuery(profile_id!, { skip: !profile_id });
  const { data: profileInfo, isLoading: isProfileLoading } = useDailyPersonalFetchQuery({
    profile_id: profile_id!,
    date: date
  });
  const { data: natalChartInfo, isLoading: isNatalChartLoading } = useNatalChartProfileFetchQuery(profile_id!);
  const [ updateProfile ] = useProfileUpdateMutation();
  const [ deleteProfile ] = useProfileDeleteMutation();
  const [ createPaymentLink, { isError: isCreatePaymentError } ] = usePaymentCreateMutation();

  useEffect(() => {
    setMainButtonVisible(false);
    setBackButtonVisible(true);
    setBackButtonOnClick(() => navigate("/"));
  }, []);

  useEffect(() => {
    if (!!profile) {
      setFormProfileData(profile)
    }
  }, [ profile ]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (profile && profile.status !== "ready") {
      intervalId = setInterval(() => {
        refetch();
      }, 5000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [ profile?.status ]);

  if (isLoading || isProfileLoading || isNatalChartLoading) return <div/>;
  if (!profile) {
    navigate("/");
    return null;
  }

  if (!profileInfo || !natalChartInfo || profile.status !== "ready") {
    return (
      <div className="flex flex-col justify-center items-center h-full w-full">
        <div className="mb-5">
          <img src="/logo.svg" className="w-48 h-48" alt="Loading"/>
        </div>
        <p>Идет подготовка профиля</p>
        <p>нужно немного подождать ;)</p>
      </div>
    );
  }

  const validateProfile = () => {
    const invalid = [];
    if (!formProfileData.first_name) invalid.push("first_name");
    if (!formProfileData.last_name) invalid.push("last_name");
    if (!formProfileData.birthday) invalid.push("birthday");
    return invalid;
  };

  const handleChange = (data: Partial<Profile>) => {
    setInvalidFields([]);
    setFormProfileData({ ...formProfileData, ...data })
  }

  const handlePayment = async () => {
    try {
      let optionId = PaymentOptionType.AdditionSubscription
      if (formProfileData.is_default) {
        optionId = PaymentOptionType.BasicSubscription
      }
      const paymentData = await createPaymentLink({
        option_id: optionId,
        entity_id: formProfileData.id,
      }).unwrap();
      if (!isCreatePaymentError) {
        webApp.openInvoice(paymentData.link, (event: any) => {
          if (event === PaymentStatus.Paid) {
            setTimeout(() => refetch(), 2000);
          }
        });
      }

    } catch (err) {
      if (err instanceof Error) {
        handleError(err, err.message);
      } else {
        handleError(err, "An unexpected error occurred");
      }
    }
  }

  const handleRemove = async () => {
    try {
      const result = await deleteProfile(profile.id).unwrap();
      if (result) {
        closeDrawer()
        navigate("/")
      }
    } catch (err) {
      if (err instanceof Error) {
        handleError(err, err.message);
      } else {
        handleError(err, "An unexpected error occurred");
      }
    }
  }

  const submit = async () => {
    const invalid = validateProfile();
    if (invalid.length > 0) {
      setInvalidFields(invalid);
      return;
    }
    setInvalidFields([]);

    try {
      const updateData = {
        id: formProfileData.id,
        data: {
          ...formProfileData,
          birthday: formProfileData.birthday / 1000,
        }
      };

      const profileResult = await updateProfile(updateData).unwrap();
      if (profileResult) {
        closeDrawer();
      }
    } catch (err) {
      if (err instanceof Error) {
        handleError(err, err.message);
      } else {
        handleError(err, "An unexpected error occurred");
      }
    }
  }

  const period = profileInfo?.zodiac ? getZodiacPeriod(profileInfo.zodiac) : null;
  const recommendations = [
    ...(profileInfo?.recommendations?.positives?.map(r => ({ text: r, isPositive: true })) || []),
    ...(profileInfo?.recommendations?.negatives?.map(r => ({ text: r, isPositive: false })) || []),
  ];

  const formatHoroscopeDate = (date: number): string => {
    return moment.unix(date).locale("ru").format("D MMMM YYYY, dddd");
  };

  return (
    <div>
      <div className="w-full constellation mb-10">
        <div className="flex flex-col px-5">

          <div className="mb-5 flex flex-col items-center justify-center mt-8">
            {formProfileData?.zodiac && (
              <img
                className="w-12 h-12 mx-auto"
                src={`/ico/constellation/Constellation=${getZodiac(formProfileData.zodiac)}, Color=white.svg`}
                alt={formProfileData.zodiac}
              />
            )}
            <h1 className="text-3xl font-bold py-4 text-center text-tg-theme-section-header capitalize">
              {formProfileData?.first_name!}
            </h1>
            <div className="text-center">
              {formProfileData?.zodiac && (
                <>
                  <p className="text-tg-theme-subtitle">
                    {formProfileData.zodiac.charAt(0).toUpperCase() + formProfileData.zodiac.slice(1).toLowerCase()}
                  </p>
                  {period && (
                    <p className="text-tg-theme-subtitle">
                      {`${period.start.day.toString().padStart(2, "0")}.${period.start.month.toString().padStart(2, "0")} 
                      - ${period.end.day.toString().padStart(2, "0")}.${period.end.month.toString().padStart(2, "0")}`}
                    </p>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="mb-5">
            <NatalChart
              ascendant={110}
              planets={natalChartInfo.planets}
              houses={natalChartInfo.houses}
              aspects={natalChartInfo.aspects}
              zodiacSign={profileInfo?.zodiac}
              showHouseNumbers={true}
              showDegrees={true}
            />
          </div>

          <div className="mx-5">
            <NatalChartCard profileInfo={profileInfo} navigate={navigate}/>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-16">
        <Section title="Ваши планеты">
          <ZodiacPlanet data={profileInfo.zodiac_planet}/>
        </Section>

        <Section
          title={
            <>
              <div>Ваш персональный гороскоп</div>
              <div className="text-tg-theme-section-header">
                {profileInfo.date && formatHoroscopeDate(profileInfo.date)}
              </div>
            </>
          }
        >
          <div className="whitespace-pre-wrap">
            {profileInfo.horoscope}
          </div>
        </Section>

        <Section>
          <Card>
            <div className="w-full text-center text-tg-theme-section-header mb-3">
              Счастливые числа
            </div>
            <HappyNumber numbers={profileInfo.happy_numbers}/>
          </Card>
        </Section>

        {!formProfileData.extended && (
          <Section>
            <ProfileSubscriptionForm onClick={handlePayment}/>
          </Section>
        )}

        {formProfileData.extended && (
          <>
            {!!profileInfo?.compatibilities && (
              <Section title="Совместимость сегодня">
                <ProfileCompatibilityList compatibilities={profileInfo?.compatibilities}/>
              </Section>
            )}

            <Section title="Рекомендации на сегодня">
              <RecommendationInfo data={recommendations}/>
            </Section>

            <Section title="Календарь эффективности">
              <EfficiencyCalendarFeature profile_id={formProfileData?.id}/>
            </Section>

            <Section title="История запросов персональных совместимостей" titleClassName="text-[#AFAFDE]">
              <CompatibilityHistoryList profile_id={formProfileData?.id}/>
            </Section>

            <Section title="Настройка профайла" titleClassName="text-[#AFAFDE]">
              <div className="flex flex-col items-center">
                <img
                  src={`/img/constellation/${getZodiac(formProfileData.zodiac)}.svg`}
                  alt={getZodiac(formProfileData.zodiac)}
                  className="w-12 object-contain my-5"
                />
                <p className="mt-4 text-center text-tg-theme-section-header capitalize">
                  {`${formProfileData.first_name || ""} ${formProfileData.last_name || ""}`.trim()}
                </p>
                <button
                  className="mt-4 px-10 py-3 rounded-xl bg-card-gradient text-tg-theme-section-header w-[12rem]"
                  onClick={() => openDrawer("profile_update")}
                >
                  Изменить
                </button>
              </div>
            </Section>

            <DeleteProfileSection onDelete={() => openDrawer("profile_delete")}/>

            <Drawer name="profile_delete" maxHeight="30vh">
              <div className="flex flex-col justify-center items-center w-full h-full">
                <p>Вы уверены что хотите удалить профайл?</p>
                <button className="mt-4 px-10 py-1 border border-red-500 text-red-500 rounded-xl bg-card-gradient"
                  onClick={handleRemove}>
                  Подтверждаю, удаление
                </button>
              </div>
            </Drawer>
          </>
        )}
      </div>

      <Drawer name="profile_update" title="Персональная информация">
        <>
          <div className="mb-5">
            <ProfileForm value={formProfileData} onChange={handleChange} invalidFields={invalidFields}/>
          </div>

          <div className="w-full flex justify-center">
            <button className="mt-5 px-8 py-2 rounded-xl bg-card-gradient text-tg-theme-section-header "
              onClick={submit}
            >
              Обновить
            </button>
          </div>
        </>
      </Drawer>
    </div>
  );
};

interface SectionProps {
  title?: string | React.ReactNode;
  children: React.ReactNode;
  className?: string;
  titleClassName?: string;
}

const Section = ({
  title,
  children,
  className = "",
  titleClassName = "text-tg-theme-section-header"
}: SectionProps) => (
  <div className={`mx-10 ${className}`}>
    {title && (
      <div className="w-full text-center mb-5">
        <h2 className={titleClassName}>{title}</h2>
      </div>
    )}
    {children}
  </div>
);

const NatalChartCard = ({ profileInfo, navigate }: {profileInfo: any; navigate: (path: string) => void}) => (
  <div className="grid text-center">
    <div className="mb-2 text-[#AFAFDE]">Натальная карта и астропрофиль</div>
    <Card
      className="flex items-center justify-between"
      onClick={() => navigate(`/profile/${profileInfo.profile_id}/natal_chart`)}
    >
      <div className="flex items-center">
        <div className="me-5">
          <img
            src={`/img/constellation/${profileInfo.zodiac.toLowerCase()}.svg`}
            alt={profileInfo.zodiac.toLowerCase()}
            className="w-5 object-contain"
          />
        </div>
        <div className="text-tg-theme-section-header">
          {`${profileInfo.first_name || ""} ${profileInfo.last_name || ""}`.trim()}
        </div>
      </div>
      <div>
        <img src="/ico/arrow-right.svg" alt=""/>
      </div>
    </Card>
  </div>
);

const DeleteProfileSection = ({ onDelete }: {onDelete: () => void}) => (
  <Section>
    <Card>
      <div className="flex flex-col justify-center items-center w-full h-full">
        <h2 className="text-lg font-bold">Удаление профиля</h2>
        <div className="text-center py-2">
          Будьте аккуратны - данное действие необратимо.
        </div>
        <button
          className="mt-4 px-10 py-1 border border-red-500 text-red-500 rounded-xl bg-card-gradient"
          onClick={onDelete}
        >
          Удалить
        </button>
      </div>
    </Card>
  </Section>
);

export default ProfileInfoPage;
