import { useEffect } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import { useAccount } from "hooks/useAccount";
import { useTheme } from "context/theme.provider";
import { useScreen, useTelegram } from "context/telegram.provider";
import SunInfluence from "widgets/components/suninfluence";
import GrowStage from "widgets/components/growstage";
import ZodiacPlanet from "widgets/components/zodiacplanet";
import ZodiacElements from "widgets/components/zodiacelements";
import { useWebApp } from "app/telegram";
import { getElement, getElementString, Zodiac } from "../app/types/zodiac";
import { FeatureWelcomePath } from "features/welcome/routes";
import { useProfileListQuery } from "app/services/profile";
import { useDailyCommonFetchQuery } from "app/services/astrology";
import { ProfileListFeature } from "features/profile/profile";
import { ScreenHelloPath } from "features/welcome/screens/screenHello";
import EfficiencyCalendarFeature from "../features/efficiency/efficiency_calendar";
import ZodiacList from "features/zodiac/zodiacList";
import Oops from "widgets/components/oops";
import moment from "moment"

const formatDate = (date: Date): string => {
  return moment(date).locale("ru").format("D MMMM YYYY, dddd");
};

export const HomePage = () => {

  const navigate = useNavigate();
  const { account, settings } = useAccount();
  const { setBackButtonVisible } = useScreen();
  const { toggleElementTheme } = useTheme()

  const { setMainButtonVisible } = useTelegram()

  const webApp = useWebApp();
  const startParam = webApp.initDataUnsafe.start_param;

  const {
    data: profiles,
    isLoading: isProfilesLoading,
  } = useProfileListQuery();
  const {
    data: daily,
    isLoading
  } = useDailyCommonFetchQuery();

  useEffect(() => {
    setBackButtonVisible(false);
    setMainButtonVisible(false);

    if (startParam) {
      webApp.initDataUnsafe.start_param = "";
      const startapp = /(\w+)-(.*)/;
      const [ , target, id, date ] = startParam.match(startapp) || [];
      if (target === "dailyzodiac" && id && date) return navigate(`zodiac/${id}?date=${date}`);
      if (target === "dailypersonal" && id && date) return navigate(`profile/${id}?date=${date}`);
    }
  }, []);

  useEffect(() => {
    toggleElementTheme(getElement(Zodiac.ARIES) || "air")
  }, [ account ]);

  if (isProfilesLoading || isLoading) return <div/>;

  if (!profiles?.length) {
    return <Navigate to={FeatureWelcomePath(ScreenHelloPath)}/>;
  }

  if (!daily) return <Oops/>;

  if (startParam) {
    const startapp = /(\w+)-(.*)/;
    const [ , target ] = startParam.match(startapp) || [];
    if (!target) return <></>;
  }

  return (
    <div>
      <div className="w-full constellation">
        <div className="flex justify-between w-full p-5">
          <NavLink to="/settings" className="flex justify-center items-center bg-card-gradient rounded-full h-10 w-10">
            <i className="fa-solid fa-gear"/>
          </NavLink>
        </div>

        <div className="w-full glow">
          <div className="w-full text-center">
            <h1 className="text-2xl capitalize">
              {account.first_name || account.username}
            </h1>
            <time className="text-xs text-tg-theme-subtitle">
              {formatDate(new Date())}
            </time>
          </div>

          <div className="mx-5 my-5">
            <SectionTitle>Влияние солнца</SectionTitle>
            <SunInfluence data={daily.sun_influence}/>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-12">
        <div className="mx-5">
          <GrowStage title="Стадия роста" data={daily.lunar_growth_stage}/>
        </div>

        <div className="mx-5">
          <SectionTitle>Ваши планеты</SectionTitle>
          <ZodiacPlanet data={daily.zodiac_planet}/>
        </div>

        <div className="mx-5">
          <ProfileListFeature/>
        </div>

        <div className="mx-5">
          <SectionTitle>Календарь эффективности</SectionTitle>
          <EfficiencyCalendarFeature/>
        </div>

        <div className="mx-5">
          <SectionTitle>Тройственность</SectionTitle>
          <div className="h-[300px] flex items-center justify-center">
            <ZodiacElements element={getElementString(daily.zodiac_element)}/>
          </div>
        </div>

        <div className="mx-5">
          <SectionTitle>Отслеживаемые гороскопы</SectionTitle>
          <ZodiacList
            selectedZodiacs={settings?.zodiacs}
            showOnlySelected={true}
            onSelect={(s) => navigate(`/zodiac/${s.toLowerCase()}`)}
          />
        </div>
      </div>
    </div>
  );
};

const SectionTitle = ({ children }: {children: React.ReactNode}) => (
  <div className="w-full text-center mb-5">
    <h2 className="text-tg-theme-div-header">{children}</h2>
  </div>
);