import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAccount } from "hooks/useAccount";
import { useNavigate } from "react-router-dom";
import { FeatureWelcomePath } from "../routes";
import { ScreenBirthdayPath } from "./screenBirthday";
import { useTelegram } from "context/telegram.provider";
import { useForm } from "context/form.context";
import Card from "widgets/components/card";
import Search from "widgets/forms/search";
import { useLazyLocationListQuery } from "app/services/location";
import { Location } from "app/types/location";
import { SearchOption } from "widgets/forms/search/search";

export const ScreenProfilePath = "profile";

const ScreenProfile = () => {
  const { t } = useTranslation();
  const account = useAccount().account;
  const navigate = useNavigate();
  const { formData, updateFormData } = useForm();
  const { setMainButtonVisible, setMainButtonProps, setBackButtonVisible, setBackButtonOnClick } = useTelegram();

  const [ locations, setLocations ] = useState<Location[]>([]);
  const [ triggerLocationSearch ] = useLazyLocationListQuery();

  useEffect(() => {
    updateFormData({ ...formData, first_name: account.first_name, last_name: account.last_name });
  }, []);

  useEffect(() => {
    setBackButtonVisible(true);
    setBackButtonOnClick(() => navigate(-1));
  }, []);

  const submit = () => {
    console.log("go to birthday");
    return navigate(FeatureWelcomePath(ScreenBirthdayPath));
  };

  useEffect(() => {
    setMainButtonVisible(true);
    setMainButtonProps(t("features.welcome.screens.screenProfile.mainButton"), submit);
  }, [ setMainButtonProps ]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    updateFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSearch = (query: string) => {
    if (query.length >= 2) {
      triggerLocationSearch({ query })
        .unwrap()
        .then(setLocations);
    }
  };

  const handleLocationSelect = (selected: SearchOption) => {
    const selectedLocation = locations[Number(selected.value)];
    const patch = {
      ...formData,
      country: selectedLocation.country,
      city: selectedLocation.city,
      street: "",
      latitude: selectedLocation.latitude,
      longitude: selectedLocation.longitude,
    };
    updateFormData(patch);
  };

  return (
    <div className="flex flex-col min-h-screen px-5 constellation">
      <h1 className="text-3xl font-bold pt-8 mb-3 text-center">
        {t("features.welcome.screens.screenProfile.title")}
      </h1>

      <div className="mb-5 text-center">
        {t("features.welcome.screens.screenProfile.desc")}
      </div>

      <div className="flex-1">
        <div className="mb-2 px-0">
          <Card title={"First name"}>
            <div className="flex justify-center items-center min-h-22">
              <input
                type="text"
                name="first_name"
                className="w-full text-tg-theme-text bg-transparent border-transparent
                        focus:border-transparent focus:ring-0 focus:outline-none"
                value={formData?.first_name || ""}
                onChange={handleChange}
              />
            </div>
          </Card>
        </div>

        <div className="mb-2 px-0">
          <Card title={"Last name"}>
            <div className="flex justify-center items-center min-h-22">
              <input
                type="text"
                name="last_name"
                className="w-full text-tg-theme-text bg-transparent border-transparent
                  focus:border-transparent focus:ring-0 focus:outline-none"
                value={formData?.last_name || ""}
                onChange={handleChange}
              />
            </div>
          </Card>
        </div>

        <div className="mb-2 px-0">
          <div className="text-tg-theme-section-header text-xs uppercase ml-3 mb-2">
            Location
          </div>
          <Search
            options={locations?.map((location, index) => ({
              value: String(index),
              label: `${location.country}, ${location.city}`
            })) || []}
            value={formData?.city ? `${formData.country}, ${formData.city}` : ""}
            onSearch={handleSearch}
            onChange={handleLocationSelect}
            debounceTime={500}
            placeholder="Поиск..."
          />
        </div>
      </div>
    </div>
  );
};

export default ScreenProfile;
