import React, { useEffect } from "react";
import { useTelegram } from "context/telegram.provider";
import { useNavigate, useParams } from "react-router-dom";
import { useAccount } from "hooks/useAccount";
import { getZodiac, stringToZodiac, Zodiac } from "../../app/types/zodiac";
import ZodiacPlanet from "widgets/components/zodiacplanet";
import Card from "widgets/components/card";
import PlanetInfo from "widgets/components/planetinfo";
import { useDailyZodiacFetchQuery } from "app/services/astrology";
import moment from "moment"
import { formatDate } from "../../utils";

const getAdjacentZodiacs = (zodiacs: Zodiac[], currentZodiac: Zodiac): {previous: Zodiac; next: Zodiac} | null => {
  if (!zodiacs?.length || zodiacs.length < 2) return null;
  const currentIndex = zodiacs.indexOf(currentZodiac);
  if (currentIndex === -1) return null

  const previousIndex = (currentIndex - 1 + zodiacs.length) % zodiacs.length;
  const nextIndex = (currentIndex + 1) % zodiacs.length;

  return {
    previous: zodiacs[previousIndex],
    next: zodiacs[nextIndex]
  };
}

const ZodiacInfoPage = () => {

  const auth = useAccount();
  const navigate = useNavigate();
  const { setBackButtonVisible, setBackButtonOnClick, setMainButtonVisible } = useTelegram();

  const zodiacParam = useParams().zodiac!
  const date = useParams().date

  const {
    data: zodiacInfo,
    isLoading,
    isError,
    refetch
  } = useDailyZodiacFetchQuery({ zodiac: zodiacParam!, date: date });

  // Configure navigation routing
  useEffect(() => {
    setMainButtonVisible(false);
    setBackButtonVisible(true);
    setBackButtonOnClick(() => navigate("/"));
  }, []);

  useEffect(() => {
    refetch()
  }, [ zodiacParam, refetch ])

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div/>;
  if (!zodiacInfo) {
    navigate("/")
    return;
  }

  if (zodiacInfo.status !== "ready") {
    return (
      <div className="flex flex-col justify-center items-center h-full w-full">
        <div className="mb-5">
          <img src="/logo.svg" className="w-48 h-48" alt=""/>
        </div>
        <p>Идет подготовка данных</p>
        <p>нужно немного подождать ;)</p>
      </div>
    )
  }

  const goToSing = (zodiac: Zodiac) => {
    navigate(`/zodiac/${zodiac.toLowerCase()}`)
  };

  const adjacentZodiac = getAdjacentZodiacs(auth.settings.zodiacs || [], stringToZodiac(zodiacParam)!)

  return (
    <div className="flex flex-col min-h-screen px-5 constellation">

      <div className="mb-5 flex flex-col items-center justify-center mt-8">
        <img className="w-12 h-12 mx-auto"
          src={`/ico/constellation/Constellation=${getZodiac(zodiacInfo.zodiac)}, Color=white.svg`} alt=""/>
        <h1 className="text-3xl font-bold py-2 text-center text-tg-theme-section-header capitalize">
          {zodiacInfo.zodiac.charAt(0).toUpperCase() + zodiacInfo.zodiac.slice(1).toLowerCase()}
        </h1>
        <p className="text-xs text-tg-theme-subtitle uppercase">
          {`${moment.unix(zodiacInfo.start_period).format("DD MMMM")} — ${moment.unix(zodiacInfo.end_period).format("DD MMMM")}`}
        </p>
      </div>

      <div className="relative w-full max-w-sm mx-auto my-5">
        <div className="flex items-center justify-between">
          {auth.settings.zodiacs?.length > 1 && !!adjacentZodiac && (
            <div
              className="absolute left-0 top-1/2 transform -translate-y-1/2 p-2 h-full flex items-center cursor-pointer"
              onClick={() => goToSing(adjacentZodiac.previous)}
            >
              <img src="/ico/arrow-left.svg" alt="Previous" className="w-8 h-8"/>
            </div>
          )}

          <div className="w-40 h-40 mx-auto">
            <img className="w-full h-full" src={`/img/constellation/${getZodiac(zodiacInfo.zodiac)}.svg`} alt=""/>
          </div>

          {auth.settings.zodiacs?.length > 1 && !!adjacentZodiac && (
            <div
              className="absolute right-0 top-1/2 transform -translate-y-1/2 p-2 h-full flex items-center cursor-pointer"
              onClick={() => goToSing(adjacentZodiac.next)}
            >
              <img src="/ico/arrow-right.svg" alt="Next" className="w-8 h-8"/>
            </div>
          )}
        </div>
      </div>

      <div className="mx-5 my-10">
        <div className="w-full text-center">
          <h2 className="text-tg-theme-section-header">
            <p>Общий гороскоп тельца</p>
            <p>{formatDate(new Date())}</p>
          </h2>
        </div>
      </div>

      <div className="mx-5 mt-2">
        <ZodiacPlanet data={zodiacInfo.zodiac_planet}/>
      </div>

      <div className="mx-5 my-10">
        <div className="w-full text-center mb-5">
          <h2 className="text-tg-theme-section-header">Гороскоп на день</h2>
        </div>
        <div>
          {zodiacInfo.horoscope}
        </div>
      </div>

      <div className="mx-5 my-10">
        <div className="w-full text-center mb-10">
          <h2 className="text-tg-theme-section-header">Краткая информация о знаке</h2>
        </div>

        <div className="flex justify-between mb-4 px-5">
          <div className="flex items-center">
            <img src="/ico/star-point.svg" alt="" className="w-6 h-6 me-1"/>
            <p className="capitalize text-tg-theme-section-header">
              {zodiacInfo.zodiac_star.name}
            </p>
          </div>
          <PlanetInfo position={zodiacInfo.zodiac_star.position} starNumbers={zodiacInfo.zodiac_star.star_numbers}/>
        </div>

        <Card className="p-5">
          <div className="">
            Visible at latitudes between +90° and −65°. Best visible at 21:00 (9 p.m.) during the month of January.
          </div>
        </Card>

        <div className="my-5">

          <div className="flex flex-col px-5">
            <div className="mb-2 pb-2">
              <div className="text-tg-theme-section-header">
                Tropical zodiac
              </div>
              <div>
                {`${moment.unix(zodiacInfo.tropical_zodiac.start_period).format("DD MMMM")} 
                — ${moment.unix(zodiacInfo.tropical_zodiac.end_period).format("DD MMMM")}`}
              </div>
            </div>
          </div>
          <hr className="py-2 border-gray-400"/>
          <div className="flex flex-col px-5">
            <div className="mb-2 pb-2">
              <div className="text-tg-theme-section-header">
                Sidereal zodiac
              </div>
              <div>
                {`${moment.unix(zodiacInfo.sidereal_zodiac.start_period).format("DD MMMM")} 
                — ${moment.unix(zodiacInfo.sidereal_zodiac.end_period).format("DD MMMM")}`}
              </div>
            </div>
          </div>
        </div>

        <div className="whitespace-pre-wrap">
          {zodiacInfo.description}
        </div>
      </div>

    </div>
  );
};

export default ZodiacInfoPage
