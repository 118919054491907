import moment from "moment/moment";
import React, { FC, useMemo, useState } from "react";
import { useHapticFeedback } from "app/telegram";
import { useTranslation } from "react-i18next";

export interface DateOption {
  status: string;
  date: string;
}

interface Props {
  allowEmpty?: boolean;
  allowPrevDay?: boolean;
  disablePrevMonth?: boolean;
  selectedDay?: boolean;
  current: number;
  dates?: DateOption[];
  checkDates?: boolean;
  onChange?: (date: string) => void;
  onSelect?: (date: string) => void;
  onDateRange?: (from: string, to: string) => void;
  disableChangeMonth?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
}

const Calendar: FC<Props> = ({
  current,
  dates = [],
  allowEmpty,
  allowPrevDay,
  disablePrevMonth = true,
  selectedDay = true,
  checkDates = false,
  onChange,
  onSelect,
  onDateRange,
  disableChangeMonth = false,
  disabled = false,
  isLoading = false,
}) => {

  const { t } = useTranslation()
  const [ , , selectionChanged ] = useHapticFeedback();
  const [ currentDate, setCurrentDate ] = useState<number>(current || moment().startOf("day").unix());
  const [ selectedDate, setSelectedDate ] = useState(moment.unix(currentDate));

  const formatted: Record<string, string> = {};
  for (const { date, status } of dates) {
    formatted[date] = status;
  }

  const changeMonth = (direction: "backward" | "forward") => {
    if (isLoading) return;
    setCurrentDate(prevDate => {
      let date = moment.unix(prevDate).add(direction === "backward" ? -1 : 1, "month");

      if (disablePrevMonth) {
        const currentMonthStart = moment().startOf("month");
        if (date.isBefore(currentMonthStart, "month")) {
          return prevDate;
        }
      }

      selectionChanged();
      onDateRange && onDateRange(moment(date).startOf("month").format(), moment(date).endOf("month").format());
      return date.unix();
    });
  };

  const startDayOfMonth = moment.unix(currentDate).startOf("month").unix();

  const daysOfWeek = useMemo(() => {
    return Array.from({ length: 7 }, (_, i) => {
      const start = moment().weekday(1).hour(0).minute(0).second(0).add(i, "day");
      return { day: start.format("ddd"), date: start.format("D"), stamp: start.unix() };
    });
  }, []);

  const weekday = moment.unix(startDayOfMonth).weekday();
  let offsetDays = (weekday === 0) ? 6 : weekday - 1;

  const candyDates = useMemo(() => {
    return Array.from({ length: moment.unix(currentDate).daysInMonth() }, (_, i) => {
      const date = moment.unix(startDayOfMonth).add(i, "day");
      return {
        day: date.format("ll"),
        date: date.format("D"),
        stamp: date.unix()
      };
    });
  }, [ startDayOfMonth, currentDate ]);

  const getDayStatusStyle = (status: string) => {
    switch (status) {
      case "success":
        return "text-[#73D85C]"
      case "warning":
        return "text-[#E2AA4D]"
      case "danger":
        return "text-[#E5473B]"
      default:
        return ""
    }
  }

  return (
    <div className="w-full relative">

      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-opacity-75 z-20">
          {t("widgets.forms.calendar.loading")}
        </div>
      )}

      <div className={`px-4 flex items-center ${disableChangeMonth ? "justify-center" : "justify-between"}`}>
        <button
          aria-label="calendar backward"
          onClick={() => changeMonth("backward")}
          className={`p-4 bg-transparent text-left text-tg-theme-subtitle ${isLoading ?
            "cursor-not-allowed" : ""} ${disableChangeMonth ? "hidden" : ""}`}
          disabled={isLoading}
        >
          <i className="fa-solid fa-chevron-left"/>
        </button>
        <span className="p-2 text-[#AFAFDE]">
          {t(`common.calendar.months.${moment.unix(currentDate).format("MMMM").toLowerCase()}`)}, {moment.unix(currentDate).format("YYYY")}
        </span>
        <button
          aria-label="calendar forward"
          onClick={() => changeMonth("forward")}
          className={`p-4 bg-transparent text-right text-tg-theme-subtitle ${isLoading ?
            "cursor-not-allowed" : ""} ${disableChangeMonth ? "hidden" : ""}`}
          disabled={isLoading}
        >
          <i className="fa-solid fa-chevron-right"/>
        </button>
      </div>

      <div className={`flex items-center justify-between mt-7 relative ${isLoading ? "opacity-50" : ""}`}>

        {checkDates && !isLoading && !dates.length && (
          <div className="absolute inset-0 flex items-center justify-center bg-opacity-75 z-20"
            onClick={() => changeMonth("forward")}
          >
            <div className="flex flex-col justify-center items-center bg-tg-theme-main rounded-full p-5">
              <div className="pb-2">
                {t(`widgets.forms.calendar.no-times-in-${moment.unix(currentDate).format("MMMM").toLowerCase()}`)}
              </div>
              <div className="text-tg-theme-link">
                {t("widgets.forms.calendar.view-next-month")} <i className="fa fa-chevron-right"/>
              </div>
            </div>
          </div>
        )}

        <div className={`w-full ${disabled ? "opacity-10" : ""}`}>
          <div className="grid grid-cols-7">
            {daysOfWeek.map(({ day, date, stamp }) => (
              <div key={stamp}>
                <div className="w-full flex justify-center">
                  <p className="text-base font-medium text-center text-gray-800 dark:text-gray-100">
                    {t(`common.calendar.days-short.${day.toLowerCase()}`)}
                  </p>
                </div>
              </div>
            ))}
          </div>

          <div className="grid grid-cols-7">
            {Array(offsetDays).fill(null).map((_, i) => (
              <div className="h-10 w-10" key={i}></div>
            ))}
            {candyDates.map(({ day, date, stamp }) => {
              const isCurrent = moment(day, "ll").isSame(moment().format("ll"));
              const isSelected = selectedDay && moment(day, "ll").isSame(selectedDate, "day");
              const isDisabled = !allowPrevDay && moment(day, "ll").isBefore(moment().format("ll"));

              return (
                <div
                  className={`mt-2 flex justify-center ${!isDisabled && !isLoading ? "cursor-pointer" : ""}`}
                  key={date + "date"}
                  onClick={() => {
                    if (isLoading || isDisabled) return;
                    const selectedDay = moment(day, "ll").format("YYYY-MM-DD");
                    setSelectedDate(moment(day));
                    onSelect && onSelect(selectedDay);
                    onChange && onChange(selectedDay);
                  }}
                >
                  <div
                    className={`h-10 w-10 flex justify-center items-center rounded-xl font-bold text-[#4F6080] 
                      ${isSelected ? "border border-y-tg-theme-text" : ""}
                      ${getDayStatusStyle(formatted[day])}
                      ${isCurrent && !isSelected ? "border-2 border-tg-theme-accent-text text-tg-theme-accent-text" : ""}
                  `}
                  >
                    <p className="flex flex-col justify-center items-center text-center relative">
                      <span className={`${isCurrent ? "font-bold" : ""}`}>{date}</span>
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {disabled && (
          <div
            className="absolute inset-0 rounded-xl p-4 flex items-center justify-center">
            <p className="text-[#4F6080] font-bold text-center">
              Календарь эффективности не доступен на текущем уровне
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Calendar;
